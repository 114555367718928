import { spacings } from '@hiven-energy/hiven-ui';
import React, { FC, PropsWithChildren } from 'react';
import { Platform } from 'react-native';

import { useKeyboard } from 'src/hooks/useKeyboard';

import * as styled from './styles';

export const Form: FC<PropsWithChildren> = ({ children }) => {
  const { visible: keyboardVisible } = useKeyboard();

  return (
    <styled.Form
      contentContainerStyle={{ paddingBottom: keyboardVisible ? spacings.xxs : 0, flexGrow: 1 }}
      scrollEnabled={Platform.OS === 'android' ? true : keyboardVisible}
      showsVerticalScrollIndicator={false}
    >
      {children}
    </styled.Form>
  );
};
