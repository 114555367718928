import round from 'lodash/round';

const ENERGY_FORMAT_PRECISION = 2;

export enum EnergyUnit {
  KWH = 'kWh',
  WH = 'Wh',
}

export const PERCENT_UNIT = '%';

export const convertEnergyUnits = (value: number, currentUnit: EnergyUnit, targetUnit: EnergyUnit) => {
  if (currentUnit === targetUnit) return value;
  return currentUnit === EnergyUnit.WH ? value / 1000 : value * 1000;
};

export const roundEnergy = (value: number, precision = ENERGY_FORMAT_PRECISION) => round(value, precision);

export const formatEnergy = (value: number, unit: EnergyUnit, precision = ENERGY_FORMAT_PRECISION) =>
  `${roundEnergy(value, precision)} ${unit}`;

export const formatPercents = (value: number) => `${value}%`;

export const formatKilometers = (value: number) => `${value} km`;
