import { ArrowLeftIcon, ContainerStyle, IconProps, spacings } from '@hiven-energy/hiven-ui';
import { RouteProp, useNavigation } from '@react-navigation/native';
import React, { FC, ReactNode } from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useA11y } from 'src/a11y';
import { AccessibilityLabelId } from 'src/a11y/useA11y';
import { useAppTheme } from 'src/app-theme';
import { RouteHeaderAction, RouteId, RoutesParamList } from 'src/nav/types';
import { useDevice } from 'src/queries/sdk';
import { colors } from 'src/theme';

import * as styled from './styles';

const actionToIconMap: Record<RouteHeaderAction, FC<IconProps>> = {
  [RouteHeaderAction.GO_BACK]: ArrowLeftIcon,
};

const actionToAccessibilityLabel: Record<RouteHeaderAction, AccessibilityLabelId> = {
  [RouteHeaderAction.GO_BACK]: 'navigation.goBack',
};

const customTitleRouteIds = [RouteId.VehicleOverview, RouteId.ChargerOverview] as const;

interface Props {
  title: ReactNode;
  action?: RouteHeaderAction;
  route: RouteProp<RoutesParamList>;
  onGoBackPress?: VoidFunction;
  type?: 'modal' | 'default' | 'large';
}

const sizeToTypeMap: Record<Exclude<Props['type'], undefined>, number> = {
  modal: 94,
  default: 112,
  large: 158,
};

const Header: FC<Props> = ({ title, action, route, onGoBackPress, type = 'default' }) => {
  const a11y = useA11y();

  const appTheme = useAppTheme();

  const navigation = useNavigation();
  const insets = useSafeAreaInsets();

  const deviceId = getCustomTitleScreenDeviceId(route);
  const deviceQuery = useDevice(deviceId as string, { enabled: !!deviceId });

  const actionToHandlerMap: Record<RouteHeaderAction, VoidFunction> = {
    [RouteHeaderAction.GO_BACK]: () => {
      if (onGoBackPress) {
        onGoBackPress();
        return;
      }
      if (Platform.OS !== 'web') {
        navigation.goBack();
        return;
      }
      if (navigation.getState().routes.length > 1) {
        navigation.goBack();
        return;
      }
      navigation.navigate(RouteId.Initial);
    },
  };

  const containerStyle: ContainerStyle = {
    paddingTop: insets.top || spacings.xxs,
    paddingRight: spacings.xs,
    paddingLeft: spacings.xs,
    paddingBottom: spacings.xxs,
    minHeight: sizeToTypeMap[type],
    justifyContent: type === 'large' ? 'center' : 'flex-start',
  };

  const accessibilityLabel = action && a11y.formatLabel(actionToAccessibilityLabel[action]);

  return (
    <>
      <styled.Container style={containerStyle} backgroundColor={appTheme.main.color}>
        {action && (
          <styled.ActionButton
            type="plain"
            icon={actionToIconMap[action]}
            iconProps={{ enabled: { color: colors.white } }}
            onPress={actionToHandlerMap[action]}
            testID={accessibilityLabel}
            accessibilityLabel={accessibilityLabel}
          />
        )}
        <styled.Title variant={type === 'large' ? 'h1' : 'h2'} numberOfLines={1}>
          {deviceQuery.isSuccess ? deviceQuery.data?.attributes.name ?? title : title}
        </styled.Title>
      </styled.Container>
    </>
  );
};

const getCustomTitleScreenDeviceId = (route: RouteProp<RoutesParamList>) =>
  isCustomTitleScreen(route) ? route.params?.deviceId : undefined;

const isCustomTitleScreen = (
  route: RouteProp<RoutesParamList>,
): route is RouteProp<RoutesParamList, typeof customTitleRouteIds[number]> =>
  (customTitleRouteIds as unknown as RouteId[]).includes(route.name);

export default React.memo(Header);
