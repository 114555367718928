import { Charger, DeviceStatus, Vehicle } from '@hiven-energy/hiven-client';
import { Button, Select, Typography } from '@hiven-energy/hiven-ui';
import { useNavigation } from '@react-navigation/native';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RefreshControl } from 'react-native';

import { useA11y } from 'src/a11y';
import { useUserRefresh } from 'src/hooks/useUserRefresh';
import { RouteId } from 'src/nav/types';
import { useChargerPreferences, useChargers, useDeviceStatus, useVehicles } from 'src/queries/sdk';
import { GroupType } from 'src/screens/brands/BrandGroups/types';
import { useAnalytics } from 'src/services/analytics';
import { colors } from 'src/theme';

import { DEVICE_STATUS_REFRESH_MILLISECONDS, statusToDeviceOnline } from '../device-status/constants';

import * as styled from './styles';

const NO_SELECTION = '';

interface Props {
  chargerId?: string;
  vehicleId?: string;
  onConfirm: (chargerId: string, vehicleId: string | undefined) => void;
}

const DevicePairing: FC<Props> = ({ chargerId: initialChargerId, vehicleId: initialVehicleId, onConfirm }) => {
  const intl = useIntl();
  const a11y = useA11y();
  const navigation = useNavigation();

  const { trackButtonClick, trackRefresh } = useAnalytics();

  const [chargerId, setChargerId] = useState(initialChargerId);
  const [vehicleId, setVehicleId] = useState(initialVehicleId ?? NO_SELECTION);

  const { data: chargers } = useChargers();
  const { data: vehicles } = useVehicles();

  const isChargerFixed = !!initialChargerId;
  const isVehicleSelected = vehicleId !== NO_SELECTION;

  useEffect(() => {
    if (chargerId || !chargers?.length) return;
    setChargerId(chargers[0].id);
  }, [chargerId, chargers]);

  useChargerPreferences(chargerId!, {
    enabled: !!chargerId && !isChargerFixed,
    onSuccess: preferences => {
      setVehicleId(preferences.associatedDeviceId ?? NO_SELECTION);
    },
  });

  const {
    data: deviceStatus,
    isLoading,
    isSuccess,
    refetch,
  } = useDeviceStatus(vehicleId, {
    enabled: isVehicleSelected,
    refetchInterval: DEVICE_STATUS_REFRESH_MILLISECONDS,
  });

  const isVehicleConnecting = deviceStatus === DeviceStatus.INITIALIZATION;

  const isVehicleStatusQueryLoading = isVehicleSelected && isLoading;

  const isVehicleStatusQueryError =
    isVehicleSelected && (deviceStatus === DeviceStatus.ERROR || deviceStatus === DeviceStatus.UNKNOWN);

  const isVehicleOffline = !isVehicleStatusQueryError && isSuccess && !statusToDeviceOnline[deviceStatus];

  const isVehicleUnavailable = isVehicleConnecting || isVehicleOffline || isVehicleStatusQueryError;

  const vehicleError = isVehicleOffline
    ? 'offline'
    : isVehicleConnecting
    ? 'connecting'
    : isVehicleStatusQueryError
    ? 'query-error'
    : undefined;

  const handleChargerSelect = (chargerId: string) => {
    setChargerId(chargerId);
    setVehicleId(NO_SELECTION);
  };

  const handleVehicleSelect = (vehicleId: string) => {
    setVehicleId(vehicleId);
  };

  const handleSavePress = () => {
    trackButtonClick('common.save');
    onConfirm(chargerId!, vehicleId || undefined);
  };

  const handleAddDevicePress = () => {
    navigation.navigate(RouteId.BrandGroups, isChargerFixed ? { hiddenGroupTypes: [GroupType.CHARGER] } : undefined);
  };

  const refresh = useCallback(async () => {
    if (!isVehicleSelected) return;
    trackRefresh();
    await refetch();
  }, [isVehicleSelected, refetch, trackRefresh]);

  const [refreshing, handleRefresh] = useUserRefresh(refresh);

  const refreshControl = (
    <RefreshControl tintColor={colors.deepNavy} refreshing={refreshing} onRefresh={handleRefresh} />
  );

  return (
    <styled.Container>
      <styled.Content refreshControl={refreshControl}>
        <Typography variant="h3">
          <FormattedMessage id="DevicePairing.title" />
        </Typography>
        <ChargerSelect
          chargerId={chargerId}
          chargers={chargers}
          isFixed={isChargerFixed}
          onChange={handleChargerSelect}
        />
        <VehicleSelect vehicleId={vehicleId} error={vehicleError} vehicles={vehicles} onChange={handleVehicleSelect} />
      </styled.Content>
      <styled.ButtonsWrapper>
        <Button
          title={intl.formatMessage({ id: 'common.save' })}
          onPress={handleSavePress}
          disabled={!chargerId || isVehicleUnavailable || isVehicleStatusQueryLoading}
          testID={a11y.formatLabel('common.save')}
        />
        <Button
          type="secondary"
          title={intl.formatMessage({ id: 'DevicePairing.addDevice' })}
          onPress={handleAddDevicePress}
        />
      </styled.ButtonsWrapper>
    </styled.Container>
  );
};

interface ChargerSelectProps {
  chargerId?: string;
  chargers?: Charger[];
  isFixed: boolean;
  onChange: (chargerId: string) => void;
}

const ChargerSelect: FC<ChargerSelectProps> = ({ chargerId, chargers, isFixed, onChange }) => {
  const a11y = useA11y();

  const chargerOptions = useMemo(
    () =>
      chargers?.map(charger => ({
        value: charger.id,
        label: charger.attributes.name,
      })) ?? [],
    [chargers],
  );

  return (
    <styled.Section $first>
      <styled.SectionLabel variant="h3">
        <FormattedMessage id="DevicePairing.charger.label" />
      </styled.SectionLabel>
      <Select
        value={chargerId}
        options={chargerOptions}
        disabled={isFixed}
        numberOfLines={2}
        onChange={onChange}
        accessible={false}
        accessibilityLabel={a11y.formatLabel('DevicePairing.charger.select')}
        testID={a11y.formatLabel('DevicePairing.charger.select')}
      />
    </styled.Section>
  );
};

interface VehicleSelectProps {
  vehicleId: string;
  error?: 'connecting' | 'query-error' | 'offline';
  vehicles?: Vehicle[];
  onChange: (vehicleId: string) => void;
}

const VehicleSelect: FC<VehicleSelectProps> = ({ vehicleId, error, vehicles, onChange }) => {
  const a11y = useA11y();
  const intl = useIntl();

  const vehicleOptions = useMemo(() => {
    const unpairOption = {
      value: NO_SELECTION,
      label: intl.formatMessage({ id: 'DevicePairing.vehicle.unpair' }),
    };
    const options =
      vehicles?.map(vehicle => ({
        value: vehicle.id,
        label: vehicle.attributes.name,
      })) ?? [];
    return [unpairOption, ...options];
  }, [intl, vehicles]);

  return (
    <styled.Section>
      <styled.SectionLabel variant="h3">
        <FormattedMessage id="DevicePairing.vehicle.label" />
      </styled.SectionLabel>
      <Select
        value={vehicleId}
        options={vehicleOptions}
        error={!!error}
        numberOfLines={2}
        onChange={onChange}
        accessible={false}
        accessibilityLabel={a11y.formatLabel('DevicePairing.vehicle.select')}
        testID={a11y.formatLabel('DevicePairing.vehicle.select')}
      />
      {error === 'connecting' && (
        <styled.Status variant="hint">
          <FormattedMessage id="DevicePairing.vehicle.status.connecting" />
        </styled.Status>
      )}
      {error === 'query-error' && (
        <styled.Status variant="hint" $error>
          <FormattedMessage id="DevicePairing.vehicle.status.error" />
        </styled.Status>
      )}
      {error === 'offline' && (
        <styled.Status variant="hint" $error>
          <FormattedMessage id="DevicePairing.vehicle.status.offline" />
        </styled.Status>
      )}
    </styled.Section>
  );
};

export default React.memo(DevicePairing);
