import { Container as _Container, spacings, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Header = styled(Typography).attrs({ variant: 'h3' })`
  margin: ${spacings.xs}px ${spacings.s}px;
`;

export const Container = styled(BaseView)``;

export const LocationItem = styled(_Container)`
  border-radius: 12px;
  border-color: ${colors.pidgeonGray};
  border-width: 1px;
`;

export const TouchableContainer = styled(TouchableOpacityContainer)`
  padding: ${spacings.xs}px;
`;

export const LocationHeader = styled(_Container)`
  flex-direction: row;
  margin-bottom: ${spacings.xxxs}px;
`;

export const LocationName = styled(Typography).attrs({ variant: 'h4' })`
  flex: 1;
  margin-right: ${spacings.xxs}px;
  ${Platform.OS === 'web' &&
  css`
    word-break: break-word;
  `}
`;

export const AddressLine = styled(Typography)`
  color: ${colors.stoneGrey};
`;

export const Address = styled(_Container)`
  margin-bottom: ${spacings.xxxxs}px;
`;

export const Buttons = styled(_Container)`
  padding: ${spacings.s}px;
  gap: ${spacings.xxs}px;
`;

export const AttachedDeviceLine = styled(Typography)`
  color: ${colors.stoneGrey};
  font-size: 12px;
  line-height: 16px;
`;
