import {
  Charger,
  ChargerChargingStatus,
  ChargerIntegration,
  ChargerMeasurementUnit,
  ChargerPreferences,
  ChargerStatus,
  ChargingSession,
  ChargingSessionStatus,
  Client,
  DeviceType,
  RegisterChargerPayload,
  SetUserLocationPayload,
  SpotPriceArea,
  UpdateUserLocationPayload,
  UserLocation,
  VehicleChargingStatus,
  VehiclePreferences,
  VehicleStatus,
} from '@hiven-energy/hiven-client';
import random from 'lodash/random';
import range from 'lodash/range';

import * as fixtures from 'src/fixtures';
import { DateFormat, formatDate, getDate } from 'src/utils/date';
import { delayedResponse, log } from 'src/utils/demo';

import { createBaseClient } from './utils';

const getUserLocations = async () =>
  delayedResponse([fixtures.userLocation]).then(response => {
    log('Retrieved list of user locations', response);
    return response;
  });

const setUserLocation = async (_userId: string, payload: SetUserLocationPayload) => {
  const userLocation: UserLocation = {
    ...payload,
    id: 'id',
    spotPriceAreaId: SpotPriceArea.FI,
    tariffInfo: undefined,
  };
  return delayedResponse(userLocation).then(response => {
    log('User location has been created', payload);
    return response;
  });
};

const updateUserLocation = async (_userId: string, _locationId: string, payload: UpdateUserLocationPayload) => {
  const userLocation = {
    ...payload,
    id: 'id',
    spotPriceAreaId: SpotPriceArea.FI,
  } as UserLocation;
  return delayedResponse(userLocation).then(response => {
    log('User location has been updated', payload);
    return response;
  });
};

const getDevicesList = async () =>
  delayedResponse(fixtures.devices).then(response => {
    log('Retrieved list of devices', response);
    return response;
  });

const getDeviceDetails = async (id: string) =>
  delayedResponse(fixtures.devices.find(device => device.id === id) || fixtures.devices[0]).then(response => {
    log('Retrieved device details', response);
    return response;
  });

const getVehiclePreferences = async () =>
  delayedResponse(fixtures.vehiclePreferences).then(response => {
    log('Retrieved charging preferences', response);
    return response;
  });

const getChargerPreferences = async (deviceId: string) => {
  let preferences = fixtures.chargerPreferences;
  const device = (fixtures.devices.find(device => device.id === deviceId) || fixtures.devices[2]) as Charger;
  if (device.settings.chargingPreferences?.associatedDeviceId) {
    preferences = {
      ...preferences,
      associatedDeviceId: device.settings.chargingPreferences.associatedDeviceId,
    } as ChargerPreferences;
  }
  return delayedResponse(preferences).then(response => {
    log('Retrieved charger charging preferences', response);
    return response;
  });
};

const setVehiclePreferences = async (_deviceId: string, preferences: VehiclePreferences) =>
  delayedResponse('').then(response => {
    log('Charging prefernences have been changed', preferences);
    return response;
  });

const setChargerPreferences = async (preferences: ChargerPreferences) =>
  delayedResponse('').then(response => {
    log('Charger charging prefernences have been changed', preferences);
    return response;
  });

const createGetVehicleStatus = () => {
  let progress = 0;
  return async () => {
    const timestamp = formatDate(getDate(), DateFormat.UTC);
    progress = Math.min(progress + random(0, 2), 100);
    const isCharging = progress < 100;
    const vehicleStatus: VehicleStatus = {
      id: 'status-id',
      timestamp,
      soc: progress,
      chargingStatus: isCharging ? VehicleChargingStatus.CHARGING : VehicleChargingStatus.NOT_CHARGING,
      range: (progress * 700) / 100,
      batteryCapacity: 100,
    };
    return delayedResponse(vehicleStatus).then(response => {
      log('Retrieved device status', vehicleStatus);
      return response;
    });
  };
};

const getChargingSchedule = async () => {
  const currentDate = getDate().startOf('hour');
  const schedule = range(24).map(() => random(0, 1));
  const hourlyCharges: Record<string, number> = schedule.reduce((acc, item, index) => {
    const dateTime = formatDate(currentDate.add(index, 'hour'), DateFormat.UTC);
    acc[dateTime] = item;
    return acc;
  }, {} as Record<string, number>);
  return delayedResponse({
    id: 'id',
    createdAt: formatDate(currentDate, DateFormat.UTC),
    hourlyCharges,
    spotPriceAreaId: SpotPriceArea.FI,
    readyTime: formatDate(currentDate.add(10, 'hour'), DateFormat.UTC),
    timeZone: 'Europe/Helsinki',
  }).then(response => {
    log('Retrieved charger charging session history', response);
    return response;
  });
};

const getChargingSessionHistory = async () => {
  const currentDate = getDate().startOf('hour');
  const schedule = range(24).map(() => random(0, 1));
  const hourlyCharges: Record<string, number> = schedule.reduce((acc, item, index) => {
    const dateTime = formatDate(currentDate.add(index, 'hour'), DateFormat.UTC);
    acc[dateTime] = item;
    return acc;
  }, {} as Record<string, number>);
  return delayedResponse<ChargingSession[]>([
    {
      id: 'id',
      createdAt: formatDate(currentDate, DateFormat.UTC),
      endDateTime: formatDate(currentDate.add(24, 'hours'), DateFormat.UTC),
      status: ChargingSessionStatus.COMPLETE,
      hourlyCharges,
      spotPriceAreaId: SpotPriceArea.FI,
      readyTime: formatDate(currentDate.add(10, 'hour'), DateFormat.UTC),
      timeZone: 'Europe/Helsinki',
    },
  ]).then(response => {
    log('Retrieved charger charging session history', response);
    return response;
  });
};

const getChargerStatus = async () => {
  const timestamp = formatDate(getDate(), DateFormat.UTC);
  const chargerStatus: ChargerStatus = {
    vendorId: 'vendor-id',
    status: ChargerChargingStatus.CHARGING,
    statusTimestamp: timestamp,
    measurement: {
      timestamp,
      value: random(100, 2000),
      unit: ChargerMeasurementUnit.WH,
    },
    heartbeatTimestamp: timestamp,
    errorCode: '',
    vendorErrorCode: '',
    firmwareVersion: '5.17.59',
  };
  return delayedResponse(chargerStatus).then(response => {
    log('Retrieved charger status', response);
    return response;
  });
};

const getSpotPrices = async () => {
  const currentDate = getDate().startOf('hour');
  const prices = range(24).map(() => random(0, 400) / 1000);
  const spotPrices: Record<string, number> = prices.reduce((acc, item, index) => {
    const dateTime = formatDate(currentDate.add(index, 'hour'), DateFormat.UTC);
    acc[dateTime] = item;
    return acc;
  }, {} as Record<string, number>);
  return delayedResponse(spotPrices).then(response => {
    log('Retrieved spot prices', response);
    return response;
  });
};

const registerCharger = async (payload: RegisterChargerPayload) =>
  delayedResponse('').then(response => {
    log('Charger has been successfully registered', name);
    setTimeout(() => {
      fixtures.addDevice({
        id: 'wallbox-charger-2',
        attributes: {
          userId: 'userId',
          externalId: 'externalId',
          name: payload.name,
          partnerName: 'hiven',
        },
        integration: ChargerIntegration.OCPP,
        settings: {},
        createdAt: 'createdAt',
        type: DeviceType.CHARGER,
      });
    }, 5000);
    return response;
  });

export const createDemoHivenClient = (): Client => {
  console.log('Configured demo SC client');
  return {
    ...createBaseClient(),
    getUserLocations,
    setUserLocation,
    updateUserLocation,
    getDevicesList,
    getVehiclePreferences,
    getChargerPreferences,
    setVehiclePreferences,
    setChargerPreferences,
    getVehicleStatus: createGetVehicleStatus(),
    getChargingSchedule,
    getChargingSessionHistory,
    getChargerStatus,
    getSpotPrices,
    getDeviceDetails,
    registerCharger,
  };
};
