import { Button, InputField } from '@hiven-energy/hiven-ui';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Platform, ScrollView } from 'react-native';

import { useA11y } from 'src/a11y';
import { useConnectSuccessTarget } from 'src/hooks/useConnectSuccessTarget';
import { usePrevious } from 'src/hooks/usePrevious';
import { RouteId } from 'src/nav/types';
import { useDevices, useRegisterCharger, useSteveUrl } from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { findNewDevice } from 'src/utils/device';

import ConnectDetails from './ConnectDetails/ConnectDetails';
import * as styled from './styles';
import Success from './Success/Success';

const DEVICES_MAX_REFETCHES = 10;
const DEVICES_REFETCH_INTERVAL = 2000;

const ChargerConnect: FC = () => {
  const intl = useIntl();
  const a11y = useA11y();
  const isFocused = useIsFocused();
  const navigation = useNavigation();

  const successTarget = useConnectSuccessTarget();

  const [name, setName] = useState('');
  const [chargerId, setChargerId] = useState('');
  const [chargerExternalId, setChargerExternalId] = useState('');

  const [refetches, setRefetches] = useState(-1);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  useFocusEffect(
    useCallback(() => {
      setChargerId('');
      setChargerExternalId('');
      setRefetches(-1);
      setShouldRefetch(false);
    }, [setChargerId, setChargerExternalId, setRefetches, setShouldRefetch]),
  );

  const steveUrlQuery = useSteveUrl();

  const registerChargerMutation = useRegisterCharger();

  useAnalyticsTimeEvent(MixpanelEvents.CHARGER_REGISTERED);
  const { trackButtonClick, trackRegisterCharger } = useAnalytics();

  const isLoading = isFocused && shouldRefetch && !chargerExternalId && refetches < DEVICES_MAX_REFETCHES;

  const devicesQuery = useDevices({
    onSuccess: () => {
      setRefetches(refetches => refetches + 1);
    },
    refetchInterval: DEVICES_REFETCH_INTERVAL,
    enabled: isLoading,
  });

  const previousDevices = usePrevious(devicesQuery.data);

  useEffect(() => {
    const device = previousDevices && findNewDevice(previousDevices, devicesQuery.data ?? []);
    if (!device) return;
    setChargerId(device.id);
    setChargerExternalId(device.attributes.externalId);
  }, [devicesQuery.data, previousDevices]);

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleCreateChargeBoxPress = () => {
    successTarget.saveTarget();
    registerChargerMutation.mutate({ name });
    trackRegisterCharger('ChargerConnect.name.submit', name);
    setChargerId('');
    setChargerExternalId('');
    setRefetches(-1);
    setShouldRefetch(true);
  };

  const handleCompletePress = () => {
    trackButtonClick('ChargerConnect.complete');
    setIsSuccess(true);
  };

  const handleContinue = () => {
    const target = successTarget.getTarget();
    const [targetRouteName] = target;
    if (Platform.OS === 'web') {
      if (targetRouteName === RouteId.ChargerDashboard && chargerId) {
        navigation.navigate(RouteId.ChargerDashboard, { deviceId: chargerId });
        return;
      }
      navigation.navigate(...target);
    } else {
      if (targetRouteName === RouteId.ChargerDashboard && chargerId) {
        navigation.replace(RouteId.ChargerDashboard, { deviceId: chargerId });
        return;
      }
      navigation.replace(...target);
    }
  };

  if (isSuccess) {
    return <Success onContinue={handleContinue} />;
  }

  return (
    <styled.Container>
      <ScrollView>
        <styled.Section>
          <styled.Title variant="h4">
            <FormattedMessage id="ChargerConnect.name.title" />
          </styled.Title>
          <styled.InputWrapper>
            <InputField
              disabled={isLoading}
              value={name}
              onChange={handleChangeName}
              placeholder={intl.formatMessage({ id: 'ChargerConnect.name.placeholder' })}
              testID={a11y.formatLabel('ChargerConnect.name.placeholder')}
              accessibilityLabel={a11y.formatLabel('ChargerConnect.name.placeholder')}
            />
          </styled.InputWrapper>
          <Button
            title={intl.formatMessage({ id: 'ChargerConnect.name.submit' })}
            testID={a11y.formatLabel('ChargerConnect.name.submit')}
            accessibilityLabel={a11y.formatLabel('ChargerConnect.name.submit')}
            disabled={!name || isLoading}
            onPress={handleCreateChargeBoxPress}
            loading={isLoading}
          />
          {registerChargerMutation.isError ? (
            <styled.ErrorMessage>
              <FormattedMessage id="ChargerConnect.register.error" />
            </styled.ErrorMessage>
          ) : null}
        </styled.Section>
        {steveUrlQuery.isSuccess && chargerExternalId !== '' && (
          <>
            <styled.Section>
              <styled.Title variant="h4">
                <FormattedMessage id="connectDetails.title" />
              </styled.Title>
              <ConnectDetails steveUrl={steveUrlQuery.data.url} chargerExternalId={chargerExternalId} />
            </styled.Section>
            <Button title={intl.formatMessage({ id: 'ChargerConnect.complete' })} onPress={handleCompletePress} />
          </>
        )}
      </ScrollView>
    </styled.Container>
  );
};

export default React.memo(ChargerConnect);
