import { ChargerChargingBehavior, TariffInfo, UserLocation } from '@hiven-energy/hiven-client';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import ChargeLevels from 'src/containers/charge-levels/Preference/ChargeLevels';
import { useChargerLocation } from 'src/containers/charging-location/hooks';
import ChargingLocation from 'src/containers/charging-location/Preference/ChargingLocation';
import DevicePairing from 'src/containers/DevicePairing/DevicePairing';
import { ReadyTime } from 'src/containers/ready-time/Preference/ReadyTime';
import { ReadyTimeByType } from 'src/containers/ready-time/types';
import { createReadyTimeByType } from 'src/containers/ready-time/utils';
import TariffInformation from 'src/containers/TariffInformation/TariffInformation';
import YourChargingLocations from 'src/containers/YourChargingLocations/YourChargingLocations';
import { useHeaderTitle } from 'src/hooks/useHeaderTitle';
import { RouteId, ScreenProps } from 'src/nav/types';
import {
  useChargerPreferences,
  useSetOrUpdateUserLocation,
  useUpdateChargerVehiclePreferences,
  useVehiclePreferences,
  useVehicleTelemetry,
} from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { Address, Region } from 'src/services/maps';
import { ErrorReason } from 'src/utils/queries';

import {
  chargeLevelLimit,
  chargeLevelUnit,
  chargerPreferenceTitles,
  initialChargeLevels,
  initialPreferences,
  minimumChargeLevelHintThreshold,
} from '../constants';
import { Preferences as PreferencesSave, PreferenceType, SetupType } from '../types';
import {
  createChargerPreferences,
  createUserLocationPayload,
  createVehiclePreferences,
  parseBatteryCapacity,
  parseChargingLocation,
  parsePreferences,
  validateSaveConditions,
} from '../utils';

import BatteryCapacity from './BatteryCapacity/BatteryCapacity';
import ChargingBehavior from './ChargingBehavior/ChargingBehavior';
import Overview from './Overview/Overview';

export interface RoutedProps {
  deviceId: string;
  associatedDeviceId?: string;
  obtainPreferencesFromVehicle?: boolean;
  currentPreferenceType?: PreferenceType;
  updateOnPreferenceConfirm?: boolean;
  navigateToDashboard?: boolean;
}

type Props = ScreenProps<RouteId.ChargerPreferences>;

const Preferences: FC<Props> = ({ navigation, route: { params } }) => {
  const intl = useIntl();
  const toast = useToast();

  useAnalyticsTimeEvent(MixpanelEvents.CHARGER_PREFERENCES_SAVED);
  const {
    trackChargerPreferencesChange,
    trackVehicleAssociated,
    trackVehiclePreferencesChange,
    trackUserLocationSaved,
    trackButtonClick,
  } = useAnalytics();

  const {
    deviceId,
    associatedDeviceId,
    obtainPreferencesFromVehicle,
    currentPreferenceType,
    updateOnPreferenceConfirm,
    navigateToDashboard,
  } = params;
  const [preferences, setPreferences] = useState<PreferencesSave>(initialPreferences);
  const [preferenceConfirmed, setPreferenceConfirmed] = useState(false);

  const chargerPreferencesQuery = useChargerPreferences(deviceId);
  const { chargerLocation } = useChargerLocation(deviceId);

  const vehicleTelemetryQuery = useVehicleTelemetry(preferences.associatedVehicleId!, {
    enabled: preferences.setupType === SetupType.ASSOCIATED,
  });

  const vehiclePreferencesQuery = useVehiclePreferences(preferences.associatedVehicleId!, {
    enabled: preferences.setupType === SetupType.ASSOCIATED,
  });

  const setActivePreferenceType = useCallback(
    (preferenceType: PreferenceType | undefined) => {
      navigation.navigate(RouteId.ChargerPreferences, {
        deviceId,
        associatedDeviceId,
        obtainPreferencesFromVehicle,
        currentPreferenceType: preferenceType,
        navigateToDashboard,
      });
    },
    [deviceId, associatedDeviceId, obtainPreferencesFromVehicle, navigation, navigateToDashboard],
  );

  useEffect(() => {
    const { data: chargerPreferences } = chargerPreferencesQuery;
    const parsedPreferences = parsePreferences(chargerPreferences, chargerLocation);
    setPreferences(parsedPreferences);
  }, [chargerPreferencesQuery.data, chargerLocation]);

  useEffect(() => {
    const setupType = associatedDeviceId ? SetupType.ASSOCIATED : SetupType.STANDALONE;
    setPreferences(preferences => ({
      ...preferences,
      setupType,
      associatedVehicleId: associatedDeviceId,
      batteryCapacity: parseBatteryCapacity(vehicleTelemetryQuery.data, vehiclePreferencesQuery.data),
      readyTime:
        preferences.setupType === setupType
          ? obtainPreferencesFromVehicle
            ? (vehiclePreferencesQuery.data && createReadyTimeByType(vehiclePreferencesQuery.data)) ||
              preferences.readyTime
            : preferences.readyTime
          : initialPreferences.readyTime,
      chargeLevels: {
        minimum:
          preferences.setupType === setupType
            ? obtainPreferencesFromVehicle
              ? vehiclePreferencesQuery.data?.minimalSoC?.value || preferences.chargeLevels.minimum
              : preferences.chargeLevels.minimum
            : initialChargeLevels[setupType].minimum,
        maximum:
          preferences.setupType === setupType
            ? obtainPreferencesFromVehicle
              ? vehiclePreferencesQuery.data?.targetSoC?.value || preferences.chargeLevels.maximum
              : preferences.chargeLevels.maximum
            : initialChargeLevels[setupType].maximum,
      },
    }));
  }, [obtainPreferencesFromVehicle, associatedDeviceId, vehicleTelemetryQuery.data, vehiclePreferencesQuery.data]);

  useEffect(() => {
    if (preferenceConfirmed) updatePreferences();
  }, [preferenceConfirmed]);

  const preferencesMutation = useUpdateChargerVehiclePreferences({
    onSuccess: (_, { chargerPreferences, vehiclePreferences, vehicleId, chargerId }) => {
      if (chargerPreferences && chargerId) {
        trackChargerPreferencesChange(chargerPreferences, chargerId);
      }

      if (vehiclePreferences && vehicleId) {
        trackVehiclePreferencesChange(vehiclePreferences, vehicleId);
      }

      if (navigateToDashboard) {
        navigation.navigate(RouteId.ChargerDashboard, { deviceId });
      } else {
        navigation.goBack();
      }
    },
    onError: () => {
      const message = intl.formatMessage({ id: 'ChargerPreferences.update.error' });
      toast.show(message, { type: 'danger' });
    },
  });

  const locationMutation = useSetOrUpdateUserLocation({
    onSuccess: location => {
      trackUserLocationSaved(location);
    },
    onError: response => {
      if (response.status === ErrorReason.BAD_REQUEST) {
        toast.show(intl.formatMessage({ id: 'common.preferences.chargingLocation.update.address.error' }), {
          type: 'danger',
        });
      } else {
        toast.show(intl.formatMessage({ id: 'common.preferences.chargingLocation.update.tryAgain.error' }), {
          type: 'danger',
        });
      }
    },
  });

  const chargeLevelsTranslations = useMemo(
    () => ({
      'minimum.label': intl.formatMessage({ id: 'ChargerPreferences.chargeLevels.preference.minimum.label' }),
      'minimum.description': intl.formatMessage({
        id: 'ChargerPreferences.chargeLevels.preference.minimum.description',
      }),
      'maximum.label': intl.formatMessage({ id: 'ChargerPreferences.chargeLevels.preference.maximum.label' }),
      'maximum.description': intl.formatMessage({
        id: 'ChargerPreferences.chargeLevels.preference.maximum.description',
      }),
    }),
    [intl],
  );

  const handleGoBackPress = useCallback(() => {
    if (currentPreferenceType && !updateOnPreferenceConfirm) {
      resetActivePreference();
    } else {
      navigation.goBack();
    }
  }, [currentPreferenceType, chargerPreferencesQuery.isError]);

  useHeaderTitle(
    intl.formatMessage(
      currentPreferenceType
        ? chargerPreferenceTitles[currentPreferenceType]
        : { id: 'navigation.screen.ChargerPreferences' },
    ),
    handleGoBackPress,
  );

  const resetActivePreference = () => {
    setActivePreferenceType(undefined);
  };

  const updatePreferences = async () => {
    let location: UserLocation;
    try {
      location = await updateLocation();
    } catch (_error) {
      return;
    }
    updateChargerVehiclePreferences(location.id);
  };

  const updateLocation = async () => {
    const payload = createUserLocationPayload(preferences);
    return locationMutation.mutateAsync({ id: preferences.chargingLocation.chargingLocationId, payload });
  };

  const updateChargerVehiclePreferences = async (locationId: string) => {
    const chargerPreferences = createChargerPreferences(preferences, locationId);
    const previousAssociatedDeviceId = chargerPreferencesQuery.data?.associatedDeviceId;
    try {
      await preferencesMutation.mutateAsync({
        chargerId: deviceId,
        chargerPreferences,
        vehicleId: preferences.associatedVehicleId,
        vehiclePreferences: createVehiclePreferences(preferences),
      });
    } catch (_error) {
      return;
    }
    if (preferences.associatedVehicleId && preferences.associatedVehicleId !== previousAssociatedDeviceId) {
      trackVehicleAssociated(preferences.associatedVehicleId);
    }
  };

  const handleNewChargingLocationConfirm = (region: Region, address: Required<Address>, name: string) => {
    setPreferences(preferences => ({
      ...preferences,
      chargingLocation: {
        region,
        address,
        name,
        tariffInfo:
          chargerLocation?.coordinates.latitude === region.latitude &&
          chargerLocation.coordinates.longitude === region.longitude
            ? preferences.chargingLocation.tariffInfo
            : undefined,
      },
    }));
    handlePreferenceConfirm();
  };

  const handleDevicePairingConfirm = (_chargerId: string, vehicleId: string | undefined) => {
    navigation.navigate(RouteId.ChargerPreferences, {
      deviceId,
      associatedDeviceId: vehicleId,
      obtainPreferencesFromVehicle: !!vehicleId,
    });
  };

  const handleBatteryCapacityConfirm = (capacity: number) => {
    setPreferences(preferences => ({
      ...preferences,
      batteryCapacity: capacity,
    }));
    handlePreferenceConfirm();
  };

  const handleChargeLevelsConfirm = (minimum: number, maximum: number) => {
    setPreferences(preferences => ({
      ...preferences,
      chargeLevels: { minimum, maximum },
    }));
    handlePreferenceConfirm();
  };

  const handleChargingBehaviorConfirm = (value: ChargerChargingBehavior) => {
    setPreferences(preferences => ({
      ...preferences,
      chargingBehavior: value,
    }));
    handlePreferenceConfirm();
  };

  const handleReadyTimeConfirm = (readyTime: ReadyTimeByType) => {
    setPreferences(preferences => ({
      ...preferences,
      readyTime,
    }));
    handlePreferenceConfirm();
  };

  const handleChargingLocationConfirm = (location: UserLocation | undefined) => {
    setPreferences(preferences => ({
      ...preferences,
      chargingLocation: parseChargingLocation(location),
    }));
    handlePreferenceConfirm();
  };

  const handleAddNewLocationPress = () => {
    setActivePreferenceType(PreferenceType.ADD_CHARGING_LOCATION);
  };

  const handleTariffInfoEditPress = () => {
    setActivePreferenceType(PreferenceType.TARIFF_INFORMATION);
  };

  const handleTariffInfoConfirm = (model: TariffInfo) => {
    setPreferences(preferences => ({
      ...preferences,
      chargingLocation: {
        ...preferences.chargingLocation,
        tariffInfo: {
          ...model,
          electricalUtilityProvider: model.electricalUtilityProvider || undefined,
        },
      },
    }));
    handlePreferenceConfirm();
  };

  const handlePreferenceConfirm = () => {
    if (updateOnPreferenceConfirm) {
      setPreferenceConfirmed(true);
    } else {
      resetActivePreference();
    }
  };

  const handleSavePress = (clickText: string) => {
    trackButtonClick(clickText);
    updatePreferences();
  };

  const batteryCapacityInputRequired = !vehicleTelemetryQuery.data?.batteryCapacity;

  const saveAllowed = validateSaveConditions(preferences);
  const saving = locationMutation.isLoading || preferencesMutation.isLoading;

  if (currentPreferenceType === PreferenceType.ADD_CHARGING_LOCATION) {
    return (
      <ChargingLocation
        region={initialPreferences.chargingLocation.region}
        analyticsPlace="ChargerPreferences.chargingLocation"
        onConfirm={handleNewChargingLocationConfirm}
      />
    );
  }

  if (currentPreferenceType === PreferenceType.YOUR_CHARGING_LOCATIONS) {
    return (
      <YourChargingLocations
        selectedLocationId={preferences.chargingLocation.chargingLocationId}
        saving={saving}
        onConfirm={handleChargingLocationConfirm}
        onAddNewLocation={handleAddNewLocationPress}
      />
    );
  }

  if (currentPreferenceType === PreferenceType.DEVICE_PAIRING) {
    return <DevicePairing chargerId={deviceId} vehicleId={associatedDeviceId} onConfirm={handleDevicePairingConfirm} />;
  }

  if (currentPreferenceType === PreferenceType.BATTERY_CAPACITY) {
    return (
      <BatteryCapacity
        capacity={preferences.batteryCapacity}
        saving={saving}
        onConfirm={handleBatteryCapacityConfirm}
      />
    );
  }

  if (currentPreferenceType === PreferenceType.READY_TIME) {
    return <ReadyTime initialTime={preferences.readyTime} saving={saving} onConfirm={handleReadyTimeConfirm} />;
  }

  if (currentPreferenceType === PreferenceType.TARIFF_INFORMATION) {
    return (
      <TariffInformation
        tariffInformation={preferences.chargingLocation.tariffInfo}
        saveTitle={intl.formatMessage({ id: 'common.confirm' })}
        onSave={handleTariffInfoConfirm}
      />
    );
  }

  if (currentPreferenceType === PreferenceType.CHARGE_LEVELS) {
    return (
      <ChargeLevels
        minimum={preferences.chargeLevels.minimum}
        maximum={preferences.chargeLevels.maximum}
        unit={chargeLevelUnit[preferences.setupType]}
        limit={chargeLevelLimit[preferences.setupType]}
        minimumHintThreshold={minimumChargeLevelHintThreshold[preferences.setupType]}
        translations={chargeLevelsTranslations}
        saving={saving}
        onConfirm={handleChargeLevelsConfirm}
      />
    );
  }

  if (currentPreferenceType === PreferenceType.CHARGING_BEHAVIOR) {
    return (
      <ChargingBehavior
        value={preferences.chargingBehavior}
        saving={saving}
        onConfirm={handleChargingBehaviorConfirm}
      />
    );
  }

  return (
    <Overview
      preferences={preferences}
      batteryCapacityInputRequired={batteryCapacityInputRequired}
      saveAllowed={saveAllowed}
      saving={saving}
      onPreferenceEditPress={setActivePreferenceType}
      onSavePress={handleSavePress}
      onTariffInfoEditPress={handleTariffInfoEditPress}
    />
  );
};

export default Preferences;
