import { ErrorIcon } from '@hiven-energy/hiven-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { colors } from 'src/theme';

import * as styled from './styles';

const OfflineView = () => (
  <styled.Container>
    <ErrorIcon color={colors.raspberryPink} size={45} />
    <styled.Title variant="h1">
      <FormattedMessage id="common.deviceOffline" />
    </styled.Title>
  </styled.Container>
);

export default React.memo(OfflineView);
