import { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';

export const useKeyboard = () => {
  const [visible, setVisible] = useState(false);

  const dismiss = () => {
    Keyboard.dismiss();
  };

  useEffect(() => {
    const handleShow = () => setVisible(true);
    const handleHide = () => setVisible(false);

    const showSubscription = Keyboard.addListener('keyboardWillShow', handleShow);
    const hideSubscription = Keyboard.addListener('keyboardWillHide', handleHide);

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, [setVisible]);

  return { visible, dismiss };
};
