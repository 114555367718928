import { useEffect, useMemo } from 'react';

import { usePrevious } from 'src/hooks/usePrevious';
import { useChargingSchedule, useDeviceStatus } from 'src/queries/sdk';

import { statusToScheduleAvailable } from './constants';

export const useUpdatedChargingSchedule = (deviceId: string, isSmartChargeEnabled: boolean) => {
  const { data: deviceStatus, isSuccess } = useDeviceStatus(deviceId);

  const previousDeviceStatus = usePrevious(deviceStatus);
  const chargingScheduleQuery = useChargingSchedule(deviceId, {
    enabled: isSmartChargeEnabled && isSuccess && statusToScheduleAvailable[deviceStatus],
  });

  useEffect(() => {
    if (
      isSmartChargeEnabled &&
      isSuccess &&
      statusToScheduleAvailable[deviceStatus] &&
      !!previousDeviceStatus &&
      deviceStatus !== previousDeviceStatus
    ) {
      void chargingScheduleQuery.refetch();
    }
  }, [deviceStatus, isSmartChargeEnabled, isSuccess, previousDeviceStatus]);

  return useMemo(
    () => ({
      ...chargingScheduleQuery,
      enabled: isSmartChargeEnabled && isSuccess && statusToScheduleAvailable[deviceStatus],
    }),
    [deviceStatus, isSuccess, isSmartChargeEnabled, chargingScheduleQuery],
  );
};
