import { ChargerChargingBehavior } from '@hiven-energy/hiven-client';
import { defineMessages } from 'react-intl';

import { ChargeLevels, ChargeLevelUnit } from 'src/containers/charge-levels/types';
import { defaultReadyTime } from 'src/containers/ready-time/utils';
import { MessageDescriptor } from 'src/i18n';

import { Preferences, PreferenceType, SetupType } from './types';

export const chargeLevelLimit: Record<SetupType, number> = {
  [SetupType.STANDALONE]: 120,
  [SetupType.ASSOCIATED]: 100,
};

export const chargeLevelUnit: Record<SetupType, ChargeLevelUnit> = {
  [SetupType.STANDALONE]: 'kWh',
  [SetupType.ASSOCIATED]: '%',
};

export const minimumChargeLevelHintThreshold: Record<SetupType, number> = {
  [SetupType.STANDALONE]: 40,
  [SetupType.ASSOCIATED]: 60,
};

export const initialChargeLevels: Record<SetupType, ChargeLevels> = {
  [SetupType.STANDALONE]: {
    minimum: 10,
    maximum: 30,
  },
  [SetupType.ASSOCIATED]: {
    minimum: 15,
    maximum: 80,
  },
};

export const initialPreferences: Preferences = {
  setupType: SetupType.STANDALONE,
  chargingLocation: {
    region: {
      latitude: 59.3293,
      longitude: 18.0686,
    },
    address: undefined,
    name: '',
    tariffInfo: undefined,
  },
  associatedVehicleId: undefined,
  batteryCapacity: undefined,
  readyTime: { type: 'all_day', time: defaultReadyTime },
  chargeLevels: initialChargeLevels[SetupType.STANDALONE],
  chargingBehavior: ChargerChargingBehavior.SMART_CHARGE_ON,
};

export const chargerPreferenceTitles = defineMessages<PreferenceType, MessageDescriptor>({
  [PreferenceType.ADD_CHARGING_LOCATION]: {
    id: 'navigation.screen.ChargerPreferences.AddChargingLocation',
  },
  [PreferenceType.DEVICE_PAIRING]: {
    id: 'navigation.screen.ChargerPreferences.DevicePairing',
  },
  [PreferenceType.BATTERY_CAPACITY]: {
    id: 'navigation.screen.ChargerPreferences.BatteryCapacity',
  },
  [PreferenceType.READY_TIME]: {
    id: 'navigation.screen.ChargerPreferences.ReadyTime',
  },
  [PreferenceType.CHARGE_LEVELS]: {
    id: 'navigation.screen.ChargerPreferences.ChargeLevels',
  },
  [PreferenceType.CHARGING_BEHAVIOR]: {
    id: 'navigation.screen.ChargerPreferences.ChargingBehavior',
  },
  [PreferenceType.TARIFF_INFORMATION]: {
    id: 'navigation.screen.ChargerPreferences.TariffInformation',
  },
  [PreferenceType.YOUR_CHARGING_LOCATIONS]: {
    id: 'navigation.screen.ChargerPreferences.YourChargingLocations',
  },
});
