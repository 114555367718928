import { Container as _Container, spacings, TouchableOpacityContainer, Typography } from '@hiven-energy/hiven-ui';
import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(TouchableOpacityContainer).attrs({ activeOpacity: 0.5 })`
  margin-bottom: ${spacings.xxs}px;
  padding-bottom: ${spacings.xxs}px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.disabledGrey};
`;

export const Heading = styled(_Container)`
  flex-direction: row;
  align-items: center;
`;

export const IconWrapper = styled(_Container)`
  padding: ${spacings.xxxxs}px;
  border-radius: ${spacings.xxxxs}px;
  background-color: ${colors.deepNavy};
`;

export const DeviceName = styled(Typography).attrs({ variant: 'h2' })`
  flex: 1;
  margin-left: 12px;
  ${Platform.OS === 'web' &&
  css`
    word-break: break-word;
  `}
`;

export const Chips = styled(_Container)`
  margin-top: 12px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${spacings.xxxs}px;
`;

export const Chip = styled(_Container)`
  flex-shrink: 1;
  padding: ${spacings.xxxxs}px ${spacings.xxxs}px;
  flex-direction: row;
  gap: ${spacings.xxxs}px;
  border: 1px solid ${colors.disabledGrey};
  border-radius: 12px;
`;

export const ChipIconWrapper = _Container;

export const ChipLabel = styled(Typography)`
  flex-shrink: 1;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.stoneGrey};
  ${Platform.OS === 'web' &&
  css`
    word-break: break-word;
  `}
`;
