import { DeviceStatus, DeviceType, VehicleChargingBehavior } from '@hiven-energy/hiven-client';
import { spacings } from '@hiven-energy/hiven-ui';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Platform, RefreshControl, ScrollView } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import Loader from 'src/components/Loader/Loader';
import ScheduledRefresh from 'src/components/ScheduledRefresh/ScheduledRefresh';
import ChargingActions from 'src/containers/ChargingActions/ChargingActions';
import ConnectionPending from 'src/containers/dashboard/ConnectionPending/ConnectionPending';
import Offline from 'src/containers/dashboard/Offline/Offline';
import {
  DEVICE_STATUS_REFRESH_MILLISECONDS,
  statusToChargingState,
  statusToDeviceOnline,
} from 'src/containers/device-status/constants';
import Status from 'src/containers/device-status/Status/Status';
import { useOnNextStatusUpdate } from 'src/containers/device-status/useOnNextStatusUpdate';
import { useUpdatedChargingSchedule } from 'src/containers/device-status/useUpdatedChargingSchedule';
import TabBar from 'src/containers/TabBar/TabBar';
import TimeSchedule from 'src/containers/TimeSchedule/TimeSchedule';
import { useUserRefresh } from 'src/hooks/useUserRefresh';
import { RouteId, ScreenProps } from 'src/nav/types';
import {
  useDeviceStatus,
  useStartImmediateCharging,
  useStopImmediateCharging,
  useUserLocations,
  useVehicle,
  useVehiclePreferences,
  useVehicleTelemetry,
} from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useTrackSuccessUpdate } from 'src/services/analytics/useTrackSuccessUpdate';
import { colors } from 'src/theme';
import { ErrorReason, getErrorReason } from 'src/utils/queries';

import { arePreferencesSet, parsePreferences } from '../utils';

import { VEHICLE_STATUS_REFRESH_SECONDS } from './constants';
import MissingPreferences from './MissingPreferences/MissingPreferences';
import Preferences from './Preferences/Preferences';
import * as styled from './styles';

export interface RoutedProps {
  deviceId: string;
  showDeviceList?: string;
}

type Props = ScreenProps<RouteId.VehicleDashboard>;

const Dashboard: FC<Props> = ({ navigation, route: { params } }) => {
  const intl = useIntl();
  const toast = useToast();
  const { trackRefresh } = useAnalytics();

  const { deviceId } = params;
  const showDeviceList = params.showDeviceList === 'true';
  const vehicleQuery = useVehicle(deviceId);
  const { data: vehicle } = vehicleQuery;

  const deviceStatusQuery = useDeviceStatus(deviceId, {
    refetchInterval: DEVICE_STATUS_REFRESH_MILLISECONDS,
    enabled: !showDeviceList,
  });
  const { data: deviceStatus = DeviceStatus.NOT_REGISTERED } = deviceStatusQuery;

  const {
    data: vehicleTelemetry,
    isSuccess: isVehicleTelemetryAvailable,
    isFetching: isVehicleTelemetryFetching,
    refetch: refetchVehicleTelemetry,
  } = useVehicleTelemetry(deviceId, {
    structuralSharing: false,
    enabled: deviceStatus !== DeviceStatus.NOT_REGISTERED && deviceStatus !== DeviceStatus.INITIALIZATION,
    onError: error => {
      const errorReason = getErrorReason(error);
      if (errorReason === ErrorReason.NOT_FOUND) {
        const message = intl.formatMessage({ id: 'vehicleStatus.fetch.missingTelemetry' });
        toast.show(message, { type: 'warning' });
      } else {
        const message = intl.formatMessage({ id: 'vehicleStatus.fetch.error' });
        toast.show(message, { type: 'danger' });
      }
    },
  });

  const nextStatusUpdate = useOnNextStatusUpdate(deviceId, {
    enabled: isVehicleTelemetryAvailable,
    callback: refetchVehicleTelemetry,
    interval: VEHICLE_STATUS_REFRESH_SECONDS,
    timestamp: vehicleTelemetry?.timestamp,
  });

  const preferencesQuery = useVehiclePreferences(deviceId, {
    enabled: isVehicleTelemetryAvailable,
    onError: error => {
      const errorReason = getErrorReason(error);
      if (errorReason !== ErrorReason.NOT_FOUND) {
        const message = intl.formatMessage({ id: 'VehiclePreferences.fetch.error' });
        toast.show(message, { type: 'danger' });
      }
    },
  });

  const chargingScheduleQuery = useUpdatedChargingSchedule(
    deviceId,
    preferencesQuery.isSuccess && preferencesQuery.data.chargingBehavior !== VehicleChargingBehavior.SMART_CHARGE_OFF,
  );

  const userLocationsQuery = useUserLocations();
  const startImmediateChargingMutation = useStartImmediateCharging();
  const stopImmediateChargingMutation = useStopImmediateCharging();

  const preferencesSet = useMemo(
    () => preferencesQuery.data && arePreferencesSet(preferencesQuery.data),
    [preferencesQuery.data],
  );

  const preferences = useMemo(() => {
    const locationIds = preferencesQuery.data?.chargingLocationIds;
    const locations = locationIds?.length ? userLocationsQuery.data?.filter(({ id }) => locationIds.includes(id)) : [];
    return parsePreferences(preferencesQuery.data, vehicle, locations);
  }, [vehicle, preferencesQuery.data, userLocationsQuery.data]);

  useTrackSuccessUpdate(chargingScheduleQuery, MixpanelEvents.USER_WAS_WAITING_FOR_SCHEDULE);

  const navigateToPreferences = () => {
    navigation.navigate(RouteId.VehiclePreferences, { deviceId });
  };

  const handleStartImmediateCharging = () => {
    startImmediateChargingMutation.mutate(deviceId);
  };

  const handleStopImmediateCharging = () => {
    stopImmediateChargingMutation.mutate(deviceId);
  };

  const handleToggleDeviceList = () => {
    navigation.setParams({ showDeviceList: `${!showDeviceList}` });
  };

  const refresh = useCallback(async () => {
    trackRefresh();
    await Promise.all([
      refetchVehicleTelemetry(),
      chargingScheduleQuery.enabled && chargingScheduleQuery.refetch(),
      deviceStatusQuery.refetch(),
    ]);
  }, [
    chargingScheduleQuery.enabled,
    deviceStatusQuery.refetch,
    refetchVehicleTelemetry,
    chargingScheduleQuery.refetch,
    trackRefresh,
  ]);

  const [refreshing, handleRefresh] = useUserRefresh(refresh);

  const isContentReady = !(
    (isVehicleTelemetryAvailable && preferencesQuery.isLoading && deviceStatusQuery.isLoading) ||
    userLocationsQuery.isLoading
  );

  if (vehicleQuery.isLoading) {
    return <Loader fullScreen />;
  }

  return (
    <styled.Container>
      <Status
        deviceStatus={deviceStatus}
        type="vehicle"
        name={vehicle?.attributes.name}
        lastHeartbeat={vehicleTelemetry?.timestamp}
        chargeLevels={preferences.chargeLevels}
        vehicleStatus={vehicleTelemetry}
        connectionPending={deviceStatus === DeviceStatus.INITIALIZATION}
        preferencesSet={preferencesSet}
        deviceId={vehicle?.id}
        showDeviceList={showDeviceList}
        onToggleDeviceList={handleToggleDeviceList}
      />
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: spacings.xs,
          justifyContent: !isContentReady ? 'center' : 'flex-start',
        }}
        refreshControl={
          <RefreshControl tintColor={colors.deepNavy} refreshing={refreshing} onRefresh={handleRefresh} />
        }
      >
        {isContentReady && (
          <>
            {deviceStatus === DeviceStatus.INITIALIZATION && vehicle ? (
              <ConnectionPending deviceType={DeviceType.VEHICLE} createdAt={vehicle.createdAt} />
            ) : !isVehicleTelemetryAvailable ? (
              <Offline
                deviceType={DeviceType.VEHICLE}
                isRefetching={isVehicleTelemetryFetching}
                onTryAgainPress={() => Promise.all([refetchVehicleTelemetry(), deviceStatusQuery.refetch()])}
              />
            ) : !preferencesSet ? (
              <MissingPreferences
                deviceName={vehicle?.attributes.name}
                preferences={preferences}
                onSetPreferencesPress={navigateToPreferences}
              />
            ) : (
              <styled.Dashboard>
                {deviceStatusQuery.isSuccess && statusToDeviceOnline[deviceStatus] && (
                  <styled.Section>
                    <ChargingActions
                      isCharging={statusToChargingState[deviceStatus]}
                      handleStartConfirm={handleStartImmediateCharging}
                      handleEndConfirm={handleStopImmediateCharging}
                    />
                  </styled.Section>
                )}
                <styled.Section>
                  <TimeSchedule
                    chargingSchedule={chargingScheduleQuery.isSuccess ? chargingScheduleQuery.data : undefined}
                    disabled={preferences.chargingBehavior === VehicleChargingBehavior.SMART_CHARGE_OFF}
                    complete={deviceStatus === DeviceStatus.SMART_CHARGING_COMPLETED}
                  />
                </styled.Section>
                <styled.Section>
                  <Preferences preferences={preferences} />
                </styled.Section>
                <styled.Section>
                  <ScheduledRefresh
                    refreshing={isVehicleTelemetryFetching}
                    nextRefreshDate={nextStatusUpdate}
                    disabled={Platform.OS !== 'web'}
                    onRefresh={refresh}
                  />
                </styled.Section>
              </styled.Dashboard>
            )}
          </>
        )}
        {!isContentReady && <Loader color={colors.deepNavy} />}
      </ScrollView>
      <TabBar deviceId={deviceId} />
    </styled.Container>
  );
};

export default Dashboard;
