import { VehicleChargingBehavior } from '@hiven-energy/hiven-client';
import { defineMessages } from 'react-intl';

import { ChargeLevels, ChargeLevelUnit } from 'src/containers/charge-levels/types';
import { defaultReadyTime } from 'src/containers/ready-time/utils';
import { MessageDescriptor } from 'src/i18n';

import { Preferences, PreferenceType } from './types';

export const CHARGE_LEVEL_LIMIT = 100;

export const MINIMUM_CHARGE_LEVEL_HINT_THRESHOLD = 60;

export const CHARGE_LEVEL_UNIT: ChargeLevelUnit = '%';

export const initialChargeLevels: ChargeLevels = {
  minimum: 15,
  maximum: 80,
};

export const defaultRegion = {
  latitude: 59.3293,
  longitude: 18.0686,
};

export const initialPreferences: Preferences = {
  chargingLocations: [],
  readyTime: { type: 'all_day', time: defaultReadyTime },
  chargeLevels: initialChargeLevels,
  chargingBehavior: VehicleChargingBehavior.SMART_CHARGE_ANYWHERE,
};

export const vehiclePreferenceTitles = defineMessages<PreferenceType, MessageDescriptor>({
  [PreferenceType.ADD_CHARGING_LOCATION]: {
    id: 'navigation.screen.VehiclePreferences.AddChargingLocation',
  },
  [PreferenceType.READY_TIME]: {
    id: 'navigation.screen.VehiclePreferences.ReadyTime',
  },
  [PreferenceType.CHARGE_LEVELS]: {
    id: 'navigation.screen.VehiclePreferences.ChargeLevels',
  },
  [PreferenceType.CHARGING_BEHAVIOR]: {
    id: 'navigation.screen.VehiclePreferences.ChargingBehavior',
  },
  [PreferenceType.YOUR_CHARGING_LOCATIONS]: {
    id: 'navigation.screen.VehiclePreferences.YourChargingLocations',
  },
});
