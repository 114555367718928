import { DeviceType } from '@hiven-energy/hiven-client';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Timer from 'src/components/Timer/Timer';
import { MessageId } from 'src/i18n';
import { diffSeconds, getDate } from 'src/utils/date';
import { DEVICE_CONNECTING_THRESHOLD_SECONDS } from 'src/utils/device';

import * as styled from './styles';

interface Props {
  deviceType: DeviceType.CHARGER | DeviceType.VEHICLE;
  createdAt: string;
}

const titleMessageId: Record<DeviceType.CHARGER | DeviceType.VEHICLE, MessageId> = {
  [DeviceType.CHARGER]: 'charger.Dashboard.connectionPending.title',
  [DeviceType.VEHICLE]: 'vehicle.Dashboard.connectionPending.title',
};

const contentMessageId: Record<DeviceType.CHARGER | DeviceType.VEHICLE, MessageId> = {
  [DeviceType.CHARGER]: 'charger.Dashboard.connectionPending.content',
  [DeviceType.VEHICLE]: 'vehicle.Dashboard.connectionPending.content',
};

const ConnectionPending: FC<Props> = ({ deviceType, createdAt }) => {
  const seconds = () => {
    const waitDate = getDate(createdAt).add(DEVICE_CONNECTING_THRESHOLD_SECONDS, 'seconds');
    return diffSeconds(waitDate, getDate());
  };

  return (
    <styled.Container>
      <styled.Timer>
        <Timer seconds={seconds()} />
      </styled.Timer>
      <styled.Title variant="h4">
        <FormattedMessage id={titleMessageId[deviceType]} />
      </styled.Title>
      <styled.Content>
        <FormattedMessage id={contentMessageId[deviceType]} />
      </styled.Content>
    </styled.Container>
  );
};

export default React.memo(ConnectionPending);
