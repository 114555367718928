import { VehicleManufacturer } from '@hiven-energy/hiven-client';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DeviceLogo from 'src/components/DeviceLogo/DeviceLogo';
import { RouteId, ScreenProps } from 'src/nav/types';

import * as styled from './styles';

type Props = ScreenProps<RouteId.TeslaVirtualKeyAware>;

const TeslaVirtualKeyAware: FC<Props> = ({ navigation }) => {
  const intl = useIntl();

  return (
    <styled.Container>
      <styled.BrandImage>
        <DeviceLogo manufacturer={VehicleManufacturer.TESLA} width={132} height={132} />
      </styled.BrandImage>
      <styled.Description variant="h3">
        <FormattedMessage id="TeslaVirtualKeyAware.description" />
      </styled.Description>
      <styled.Description variant="h3">
        <FormattedMessage id="TeslaVirtualKeyAware.hint" />
      </styled.Description>
      <styled.BottomContent>
        <styled.Button
          type="primary"
          title={intl.formatMessage({ id: 'common.continue' })}
          onPress={() => navigation.navigate(RouteId.SmartCarConnect, { manufacturer: VehicleManufacturer.TESLA })}
        />
      </styled.BottomContent>
    </styled.Container>
  );
};

export default TeslaVirtualKeyAware;
