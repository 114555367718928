import { ChevronDownIcon, spacings } from '@hiven-energy/hiven-ui';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Animated, Easing, InteractionManager, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useAppTheme } from 'src/app-theme';
import LastHeartbeat from 'src/containers/LastHeartbeat/LastHeartbeat';
import { useAnimatedValue } from 'src/hooks/useAnimatedValue';
import { useScreenDimensions } from 'src/hooks/useScreenDimensions';
import { useToggleOnBack } from 'src/hooks/useToggleOnBack/useToggleOnBack';
import { useAnalytics } from 'src/services/analytics';
import { AnalyticPlace } from 'src/services/analytics/types';
import { colors, MAX_CONTENT_WIDTH } from 'src/theme';

import * as styled from './styles';

const HEADER_HEIGHT = 68;

interface TogglePanelProps {
  title: string;
  toggleContent: React.ReactNode | undefined;
  isActive: boolean;
  timestamp?: string;
  accessibilityLabel: string;
  analyticsPlace: AnalyticPlace;
  open: boolean;
  onToggle: VoidFunction;
  onStateChange: (isHidden: boolean) => void;
}

export const TogglePanel: FC<TogglePanelProps> = ({
  title,
  toggleContent,
  isActive,
  timestamp,
  accessibilityLabel,
  analyticsPlace,
  open,
  onToggle,
  onStateChange,
}) => {
  const animatedValue = useAnimatedValue(open ? 1 : 0);
  const { trackButtonClick } = useAnalytics();
  const appTheme = useAppTheme();
  const insets = useSafeAreaInsets();
  const [_, screenHeight] = useScreenDimensions();
  const [openAfterAnimation, setOpenAfterAnimation] = useState(false);

  useEffect(() => {
    const handle = InteractionManager.createInteractionHandle();
    if (!open) {
      onStateChange(true);
    }
    Animated.timing(animatedValue, {
      duration: 600,
      easing: Easing.elastic(0.7),
      toValue: open ? 1 : 0,
      useNativeDriver: false,
    }).start(({ finished }) => {
      InteractionManager.clearInteractionHandle(handle);
      if (open && finished) {
        onStateChange(false);
      }
      if (finished) {
        setOpenAfterAnimation(open);
      }
    });
  }, [open, animatedValue, onStateChange]);

  const handlePress = useCallback(() => {
    onToggle();
    trackButtonClick(accessibilityLabel, analyticsPlace);
  }, [onToggle, accessibilityLabel, analyticsPlace]);

  const rotate = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  });

  const height = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, screenHeight - HEADER_HEIGHT - insets.top - spacings.xs],
  });

  useToggleOnBack(open, handlePress);

  return (
    <styled.Container>
      <styled.Header
        activeOpacity={0.5}
        onPress={handlePress}
        testID={accessibilityLabel}
        accessible={false}
        accessibilityLabel={accessibilityLabel}
        disabled={!isActive}
      >
        <styled.HeaderSpace />
        <styled.Title numberOfLines={1}>{title}</styled.Title>
        <Animated.View style={{ transform: [{ rotate }] }}>
          <ChevronDownIcon color={colors.white} />
        </Animated.View>
        <styled.HeaderSpace />
      </styled.Header>
      {timestamp !== undefined && (
        <styled.Subheader>
          <LastHeartbeat heartbeat={timestamp} />
        </styled.Subheader>
      )}
      <styled.ToggleContent
        style={{
          width: '100%',
          height,
          backgroundColor: appTheme.main.color,
          overflow: 'hidden',
          ...Platform.select({
            web: {
              alignSelf: 'center',
              width: '100%',
              maxWidth: MAX_CONTENT_WIDTH,
            },
          }),
        }}
      >
        {(open || openAfterAnimation) && toggleContent}
      </styled.ToggleContent>
    </styled.Container>
  );
};
