import { Unit, UserLocation, Vehicle, VehicleChargingBehavior, VehiclePreferences } from '@hiven-energy/hiven-client';
import { UseQueryResult } from '@tanstack/react-query';
import sortBy from 'lodash/sortBy';

import { ChargeLevels } from 'src/containers/charge-levels/types';
import { ReadyTimeByType } from 'src/containers/ready-time/types';
import {
  createReadyTimeByType,
  defaultReadyTime,
  isDaysOfWeekSpecificReadyTime,
} from 'src/containers/ready-time/utils';

import { initialChargeLevels, initialPreferences } from './constants';
import { ChargingLocation, Preferences } from './types';

export const arePreferencesSet = (
  preferences: Partial<VehiclePreferences>,
): preferences is Required<VehiclePreferences> => {
  const {
    chargingBehavior,
    targetSoC,
    readyTime,
    minimalSoC,
    chargingLocationIds,
    defaultSpotPriceAreaId,
    weekDaySpecificReadyTimes,
  } = preferences;

  const isLocationSet = chargingLocationIds ? chargingLocationIds.length > 0 : false;
  const isReadyTimeSet = readyTime !== undefined || isDaysOfWeekSpecificReadyTime(weekDaySpecificReadyTimes);

  const areChargingParametersSet = [targetSoC, minimalSoC, chargingBehavior].every(
    parameter => parameter !== undefined,
  );

  return isLocationSet && !!defaultSpotPriceAreaId && areChargingParametersSet && isReadyTimeSet;
};

export const parseLocation = ({
  city,
  countryCode,
  streetAddress,
  postalCode,
  name,
  coordinates,
  id,
  tariffInfo,
}: UserLocation): ChargingLocation => ({
  payload: {
    name,
    coordinates,
    tariffInfo,
    city,
    countryCode,
    streetAddress,
    postalCode,
  },
  id,
});

export const parsePreferences = (
  vehiclePreferences: VehiclePreferences | undefined,
  vehicle: Vehicle | undefined,
  locations: UserLocation[] | undefined,
): Preferences => ({
  ...initialPreferences,
  chargingLocations: sortBy(locations?.map(parseLocation) ?? [], 'payload.name'),
  readyTime: (vehiclePreferences && createReadyTimeByType(vehiclePreferences)) || initialPreferences.readyTime,
  chargeLevels: parseChargeLevels(vehiclePreferences),
  chargingBehavior: parseChargingBehavior(vehiclePreferences, vehicle),
});

export const parseChargeLevels = (vehiclePreferences: VehiclePreferences | undefined): ChargeLevels => {
  if (vehiclePreferences?.minimalSoC && vehiclePreferences.targetSoC) {
    return {
      minimum: vehiclePreferences.minimalSoC.value,
      maximum: vehiclePreferences.targetSoC.value,
    };
  }
  return initialChargeLevels;
};

export const parseChargingBehavior = (
  vehiclePreferences: VehiclePreferences | undefined,
  vehicle: Vehicle | undefined,
): VehicleChargingBehavior => {
  if (vehiclePreferences?.chargingBehavior) {
    return vehiclePreferences.chargingBehavior;
  }
  return vehicle?.settings.locationSupported
    ? VehicleChargingBehavior.SMART_CHARGE_AT_LOCATION
    : VehicleChargingBehavior.SMART_CHARGE_ANYWHERE;
};

export const createVehiclePreferences = (preferences: Preferences, locations: UserLocation[]): VehiclePreferences => ({
  chargingLocationIds: locations.map(({ id }) => id),
  minimalSoC: {
    value: preferences.chargeLevels.minimum,
    unit: Unit.PERCENT,
  },
  targetSoC: {
    value: preferences.chargeLevels.maximum,
    unit: Unit.PERCENT,
  },
  ...convertToPreferencesReadyTime(preferences.readyTime),
  chargingBehavior: preferences.chargingBehavior,
  defaultSpotPriceAreaId: locations[0]?.spotPriceAreaId,
});

export const convertToPreferencesReadyTime = (
  readyTime: ReadyTimeByType,
): Pick<VehiclePreferences, 'readyTime' | 'weekDaySpecificReadyTimes'> => {
  return {
    readyTime: readyTime.type === 'all_day' ? readyTime.time : defaultReadyTime,
    weekDaySpecificReadyTimes: readyTime.type === 'days_of_week' ? readyTime.time : {},
  };
};

export const validateSaveConditions = (
  preferences: Preferences,
  vehicleQuery: Pick<UseQueryResult<Vehicle | undefined, Response>, 'isSuccess' | 'data'>,
  userLocationsQuery: Pick<UseQueryResult<UserLocation[], Response>, 'isSuccess'>,
) =>
  [
    !!preferences.chargingLocations.length,
    vehicleQuery.isSuccess && !!vehicleQuery.data,
    userLocationsQuery.isSuccess,
  ].every(Boolean);

export const hasId = (location: ChargingLocation): location is ChargingLocation & { id: string } => !!location.id;
