import { ChargerChargingBehavior, DeviceStatus, DeviceType } from '@hiven-energy/hiven-client';
import { spacings } from '@hiven-energy/hiven-ui';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Platform, RefreshControl, ScrollView } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import Loader from 'src/components/Loader/Loader';
import ScheduledRefresh from 'src/components/ScheduledRefresh/ScheduledRefresh';
import ChargingActions from 'src/containers/ChargingActions/ChargingActions';
import ConnectionPending from 'src/containers/dashboard/ConnectionPending/ConnectionPending';
import Offline from 'src/containers/dashboard/Offline/Offline';
import {
  DEVICE_STATUS_REFRESH_MILLISECONDS,
  statusToChargingState,
  statusToDeviceOnline,
} from 'src/containers/device-status/constants';
import ChargerStatus from 'src/containers/device-status/Status/Status';
import Status from 'src/containers/device-status/Status/Status';
import { useOnNextStatusUpdate } from 'src/containers/device-status/useOnNextStatusUpdate';
import { useUpdatedChargingSchedule } from 'src/containers/device-status/useUpdatedChargingSchedule';
import TabBar from 'src/containers/TabBar/TabBar';
import TimeSchedule from 'src/containers/TimeSchedule/TimeSchedule';
import { useScheduledCall } from 'src/hooks/useScheduledCall';
import { useUserRefresh } from 'src/hooks/useUserRefresh';
import { RouteId, ScreenProps } from 'src/nav/types';
import {
  useCharger,
  useChargerPreferences,
  useChargerTelemetry,
  useDeviceStatus,
  useStartImmediateCharging,
  useStopImmediateCharging,
  useUserLocations,
  useVehicle,
  useVehiclePreferences,
  useVehicleTelemetry,
} from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useTrackSuccessUpdate } from 'src/services/analytics/useTrackSuccessUpdate';
import { colors } from 'src/theme';
import { getDate } from 'src/utils/date';
import { DEVICE_CONNECTING_THRESHOLD_SECONDS } from 'src/utils/device';
import { ErrorReason, getErrorReason } from 'src/utils/queries';

import { arePreferencesSet, isTariffInfoSet, parseBatteryCapacity, parsePreferences } from '../utils';

import { CHARGER_STATUS_REFRESH_SECONDS, VEHICLE_STATUS_REFRESH_SECONDS } from './constants';
import MissingPreferences from './MissingPreferences/MissingPreferences';
import Preferences from './Preferences/Preferences';
import * as styled from './styles';

export interface RoutedProps {
  deviceId: string;
  showDeviceList?: string;
}

type Props = ScreenProps<RouteId.ChargerDashboard>;

const Dashboard: FC<Props> = ({ navigation, route: { params } }) => {
  const intl = useIntl();
  const toast = useToast();
  const { trackRefresh } = useAnalytics();

  const { deviceId } = params;
  const showDeviceList = params.showDeviceList === 'true';
  const chargerQuery = useCharger(deviceId);
  const { data: charger } = chargerQuery;

  const deviceStatusQuery = useDeviceStatus(deviceId, {
    refetchInterval: DEVICE_STATUS_REFRESH_MILLISECONDS,
    enabled: !showDeviceList,
  });

  const {
    refetch: refetchStatus,
    isInitialLoading: isStatusLoading,
    isFetching: isFetchingTelemetry,
  } = deviceStatusQuery;

  const {
    data: chargerTelemetry,
    refetch: refetchChargerTelemetry,
    isSuccess: isChargerTelemetryAvailable,
    isRefetching: isChargerTelemetryRefetching,
  } = useChargerTelemetry(deviceId, {
    structuralSharing: false,
    onError: error => {
      const errorReason = getErrorReason(error);
      if (errorReason === ErrorReason.NOT_FOUND) {
        const message = intl.formatMessage({ id: 'chargerStatus.fetch.missingTelemetry' });
        toast.show(message, { type: 'warning' });
      } else {
        const message = intl.formatMessage({ id: 'chargerStatus.fetch.error' });
        toast.show(message, { type: 'danger' });
      }
    },
  });

  const nextChargerStatusUpdate = useOnNextStatusUpdate(deviceId, {
    enabled: isChargerTelemetryAvailable,
    callback: refetchChargerTelemetry,
    interval: CHARGER_STATUS_REFRESH_SECONDS,
    timestamp: chargerTelemetry?.heartbeatTimestamp,
  });

  const chargerPreferencesQuery = useChargerPreferences(deviceId, {
    enabled: isChargerTelemetryAvailable,
    onError: error => {
      const errorReason = getErrorReason(error);
      if (errorReason !== ErrorReason.NOT_FOUND) {
        const message = intl.formatMessage({ id: 'ChargerPreferences.fetch.error' });
        toast.show(message, { type: 'danger' });
      }
    },
  });
  const { associatedDeviceId } = chargerPreferencesQuery.data ?? {};

  const areVehicleQueriesEnabled = !!associatedDeviceId && isChargerTelemetryAvailable;

  const { data: vehicle } = useVehicle(associatedDeviceId!, {
    enabled: areVehicleQueriesEnabled,
  });

  const {
    data: vehicleTelemetry,
    refetch: refetchVehicleTelemetry,
    isSuccess: isVehicleTelemetryAvailable,
  } = useVehicleTelemetry(associatedDeviceId!, {
    enabled: areVehicleQueriesEnabled,
    structuralSharing: false,
    onError: error => {
      const errorReason = getErrorReason(error);
      if (errorReason === ErrorReason.NOT_FOUND) {
        const message = intl.formatMessage({ id: 'vehicleStatus.fetch.missingTelemetry' });
        toast.show(message, { type: 'warning' });
      } else {
        const message = intl.formatMessage({ id: 'vehicleStatus.fetch.error' });
        toast.show(message, { type: 'danger' });
      }
    },
  });

  useOnNextStatusUpdate(associatedDeviceId!, {
    enabled: areVehicleQueriesEnabled && isVehicleTelemetryAvailable,
    callback: refetchVehicleTelemetry,
    interval: VEHICLE_STATUS_REFRESH_SECONDS,
    timestamp: vehicleTelemetry?.timestamp,
  });

  const vehiclePreferencesQuery = useVehiclePreferences(associatedDeviceId!, {
    enabled: areVehicleQueriesEnabled,
  });

  const chargingScheduleQuery = useUpdatedChargingSchedule(
    deviceId,
    chargerPreferencesQuery.isSuccess &&
      chargerPreferencesQuery.data.chargingBehavior === ChargerChargingBehavior.SMART_CHARGE_ON,
  );

  const userLocationsQuery = useUserLocations();
  const startImmediateChargingMutation = useStartImmediateCharging();
  const stopImmediateChargingMutation = useStopImmediateCharging();

  const chargingLocation = useMemo(() => {
    const { data: userLocations } = userLocationsQuery;
    const chargingLocationId = chargerPreferencesQuery.data?.locationId;
    return userLocations?.find(({ id }) => id === chargingLocationId);
  }, [chargerPreferencesQuery.data, userLocationsQuery.data]);

  const connectionWaitDateLimit = useMemo(() => {
    const currentDate = getDate();
    const waitDate = charger && getDate(charger.createdAt).add(DEVICE_CONNECTING_THRESHOLD_SECONDS, 'seconds');
    return deviceStatusQuery.data === DeviceStatus.NOT_REGISTERED &&
      !chargerTelemetry?.heartbeatTimestamp &&
      waitDate &&
      waitDate > currentDate
      ? waitDate
      : undefined;
  }, [charger, chargerTelemetry, deviceStatusQuery]);

  const deviceStatus =
    (connectionWaitDateLimit ? DeviceStatus.INITIALIZATION : deviceStatusQuery.data) ?? DeviceStatus.INITIALIZATION;

  useScheduledCall(() => Promise.all([refetchChargerTelemetry(), deviceStatusQuery.refetch()]), {
    targetDate: connectionWaitDateLimit!,
    enabled: !!connectionWaitDateLimit,
  });

  useScheduledCall(refetchChargerTelemetry, {
    targetDate: nextChargerStatusUpdate,
    enabled:
      deviceStatus !== DeviceStatus.INITIALIZATION &&
      deviceStatus !== DeviceStatus.OFFLINE &&
      deviceStatus !== DeviceStatus.NOT_REGISTERED,
  });

  useEffect(() => {
    if (deviceStatus === DeviceStatus.CABLE_PLUGGED && !chargerTelemetry) {
      void refetchChargerTelemetry();
    }
  }, [deviceStatus, chargerTelemetry]);

  const preferencesSet = useMemo(
    () =>
      chargerPreferencesQuery.data &&
      arePreferencesSet(chargerPreferencesQuery.data) &&
      chargingLocation &&
      isTariffInfoSet(chargingLocation.tariffInfo),
    [chargerPreferencesQuery.data, chargingLocation],
  );

  const preferences = useMemo(
    () => ({
      ...parsePreferences(chargerPreferencesQuery.data, chargingLocation),
      batteryCapacity: parseBatteryCapacity(vehicleTelemetry, vehiclePreferencesQuery.data),
    }),
    [chargerPreferencesQuery.data, chargingLocation, vehicleTelemetry, vehiclePreferencesQuery.data],
  );

  useTrackSuccessUpdate(chargingScheduleQuery, MixpanelEvents.USER_WAS_WAITING_FOR_SCHEDULE);

  const navigateToPreferences = () => {
    navigation.navigate(RouteId.ChargerPreferences, { deviceId, associatedDeviceId });
  };

  const handleStartImmediateCharging = () => {
    startImmediateChargingMutation.mutate(deviceId);
  };

  const handleStopImmediateCharging = () => {
    stopImmediateChargingMutation.mutate(deviceId);
  };

  const handleToggleDeviceList = () => {
    navigation.setParams({ deviceId, showDeviceList: `${!showDeviceList}` });
  };

  const refresh = useCallback(async () => {
    trackRefresh();
    await Promise.all([
      refetchChargerTelemetry(),
      refetchStatus(),
      chargingScheduleQuery.enabled && chargingScheduleQuery.refetch(),
      areVehicleQueriesEnabled && refetchVehicleTelemetry(),
    ]);
  }, [
    chargingScheduleQuery.enabled,
    areVehicleQueriesEnabled,
    refetchChargerTelemetry,
    refetchVehicleTelemetry,
    chargingScheduleQuery.refetch,
    refetchStatus,
    trackRefresh,
  ]);

  const [refreshing, handleRefresh] = useUserRefresh(refresh);

  const isContentReady = !(
    (isChargerTelemetryAvailable && chargerPreferencesQuery.isLoading) ||
    userLocationsQuery.isLoading
  );

  const isOffline = deviceStatus === DeviceStatus.NOT_REGISTERED || deviceStatus === DeviceStatus.OFFLINE;

  if (chargerQuery.isLoading || isStatusLoading) {
    return <Loader fullScreen />;
  }

  return (
    <styled.Container>
      {associatedDeviceId ? (
        <Status
          type="paired"
          deviceStatus={deviceStatus}
          name={vehicle?.attributes.name}
          pairedChargerName={charger?.attributes.name}
          lastHeartbeat={vehicleTelemetry?.timestamp}
          chargeLevels={preferences.chargeLevels}
          vehicleStatus={vehicleTelemetry}
          connectionPending={deviceStatus === DeviceStatus.INITIALIZATION}
          isOffline={isOffline}
          preferencesSet={preferencesSet}
          deviceId={charger?.id}
          showDeviceList={showDeviceList}
          onToggleDeviceList={handleToggleDeviceList}
        />
      ) : (
        <ChargerStatus
          type="charger"
          deviceId={charger?.id}
          chargeLevels={preferences.chargeLevels}
          lastHeartbeat={chargerTelemetry?.heartbeatTimestamp}
          name={charger?.attributes.name}
          connectionPending={deviceStatus === DeviceStatus.INITIALIZATION}
          isOffline={isOffline}
          preferencesSet={preferencesSet}
          deviceStatus={deviceStatus}
          chargerStatus={chargerTelemetry}
          showDeviceList={showDeviceList}
          onToggleDeviceList={handleToggleDeviceList}
        />
      )}
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: spacings.xs,
          justifyContent: !isContentReady ? 'center' : 'flex-start',
        }}
        refreshControl={
          <RefreshControl tintColor={colors.deepNavy} refreshing={refreshing} onRefresh={handleRefresh} />
        }
      >
        {isContentReady && (
          <>
            {deviceStatus === DeviceStatus.INITIALIZATION && charger ? (
              <ConnectionPending deviceType={DeviceType.CHARGER} createdAt={charger.createdAt} />
            ) : isOffline ? (
              <Offline
                deviceType={DeviceType.CHARGER}
                isRefetching={isChargerTelemetryRefetching}
                onTryAgainPress={() => Promise.all([refetchChargerTelemetry(), refetchStatus()])}
              />
            ) : !preferencesSet ? (
              <MissingPreferences
                deviceName={charger?.attributes.name}
                preferences={preferences}
                onSetPreferencesPress={navigateToPreferences}
              />
            ) : (
              <styled.Dashboard>
                {statusToDeviceOnline[deviceStatus] && (
                  <styled.Section>
                    <ChargingActions
                      isCharging={statusToChargingState[deviceStatus]}
                      handleStartConfirm={handleStartImmediateCharging}
                      handleEndConfirm={handleStopImmediateCharging}
                    />
                  </styled.Section>
                )}
                <styled.Section>
                  <TimeSchedule
                    chargingSchedule={
                      chargingScheduleQuery.isSuccess && chargingScheduleQuery.enabled
                        ? chargingScheduleQuery.data
                        : undefined
                    }
                    disabled={preferences.chargingBehavior === ChargerChargingBehavior.SMART_CHARGE_OFF}
                  />
                </styled.Section>
                <styled.Section>
                  <Preferences preferences={preferences} />
                </styled.Section>
                <styled.Section>
                  <ScheduledRefresh
                    refreshing={isFetchingTelemetry}
                    nextRefreshDate={nextChargerStatusUpdate}
                    disabled={Platform.OS !== 'web'}
                    onRefresh={handleRefresh}
                  />
                </styled.Section>
              </styled.Dashboard>
            )}
          </>
        )}
        {!isContentReady && <Loader color={colors.deepNavy} />}
      </ScrollView>
      <TabBar deviceId={deviceId} />
    </styled.Container>
  );
};

export default Dashboard;
