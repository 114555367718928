import { Container as _Container, Button as HivenButton, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  padding: ${spacings.xs}px ${spacings.s}px;
`;

export const Button = styled(HivenButton)`
  width: 100%;
  align-self: flex-end;
  justify-self: flex-end;
`;

export const LogoContent = styled(_Container)`
  align-items: center;
  padding: ${spacings.s}px 0;
  border-width: 1px;
  border-radius: 12px;
  border-color: ${colors.pidgeonGray};
  margin-bottom: ${spacings.s}px;
  justify-content: center;
`;

export const BottomContent = styled(_Container)`
  flex: 1;
  align-self: stretch;
  justify-content: flex-end;
  gap: ${spacings.xs}px;
`;

export const Description = styled(Typography)`
  margin-bottom: ${spacings.s}px;
`;
