import { ChargingSchedule, DeviceStatus, Vehicle, VehiclePreferences, VehicleStatus } from '@hiven-energy/hiven-client';
import { useNavigation } from '@react-navigation/native';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import Divider from 'src/components/Divider/Divider';
import Properties from 'src/containers/device-overview/Properties/Properties';
import { RouteId } from 'src/nav/types';
import { useDeleteDevice } from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';

import * as styled from './styles';
import { prepareDetails, prepareDiagnostics } from './utils';

interface Props {
  vehicle: Vehicle;
  deviceStatus: DeviceStatus;
  vehicleStatus?: VehicleStatus;
  preferences?: VehiclePreferences;
  schedule?: ChargingSchedule;
}

const DeviceDetails: FC<Props> = ({ vehicle, vehicleStatus, deviceStatus, preferences, schedule }) => {
  const intl = useIntl();
  const toast = useToast();
  const navigation = useNavigation();
  const { trackButtonClick, trackDeleteDevice } = useAnalytics();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteDeviceMutation = useDeleteDevice({
    onSuccess: () => {
      toast.show(intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.delete.success' }), { type: 'success' });
      navigation.navigate(RouteId.Initial);
    },
    onError: () => {
      const message = intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.delete.error' });
      toast.show(message, { type: 'danger' });
    },
  });

  const details = prepareDetails(vehicle, vehicleStatus, intl);
  const diagnostics = prepareDiagnostics(vehicle, deviceStatus, vehicleStatus, preferences, schedule, intl);
  const standalone = !vehicle.associatedChargerIds.length;

  const handleDeleteDevicePress = () => {
    trackButtonClick('common.deviceOverview.deviceDetails.delete.button');
    if (standalone) {
      setShowDeleteModal(true);
    } else {
      toast.show(intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.remove.error.detachRequired' }), {
        type: 'danger',
      });
    }
  };

  const handleDeleteDeviceCancel = () => {
    trackButtonClick('common.cancel');
    setShowDeleteModal(false);
  };

  const handleDeleteDeviceConfirm = () => {
    trackDeleteDevice('common.confirm', vehicle.id);
    deleteDeviceMutation.mutate(vehicle.id);
    setShowDeleteModal(false);
  };

  return (
    <>
      <styled.Container>
        <Properties
          title={intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.title' })}
          properties={details}
        />
        <Divider />
        <Properties
          title={intl.formatMessage({ id: 'common.deviceOverview.diagnostics.title' })}
          properties={diagnostics}
        />
        <Divider />
        <styled.DeleteButton
          title={intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.delete.button' })}
          onPress={handleDeleteDevicePress}
        />
      </styled.Container>
      {showDeleteModal && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.delete.modal.title' })}
          content={intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.delete.modal.content' })}
          onConfirm={handleDeleteDeviceConfirm}
          onCancel={handleDeleteDeviceCancel}
        />
      )}
    </>
  );
};

export default React.memo(DeviceDetails);
