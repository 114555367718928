import { DeviceStatus, Vehicle as VehicleDevice } from '@hiven-energy/hiven-client';
import { CarIcon } from '@hiven-energy/hiven-ui';
import { useNavigation } from '@react-navigation/native';
import React, { FC } from 'react';
import { InteractionManager, Platform } from 'react-native';

import { DEVICE_STATUS_REFRESH_MILLISECONDS, statusToDeviceOnline } from 'src/containers/device-status/constants';
import { RouteId } from 'src/nav/types';
import { useDeviceStatus } from 'src/queries/sdk';

import DeviceItem from '../DeviceItem';
import PairedVehicle from '../PairedVehicle';

interface Props {
  device: VehicleDevice;
  selectedDeviceId: string;
  onPress?: VoidFunction;
}

export const Vehicle: FC<Props> = ({ device, selectedDeviceId, onPress }) => {
  const {
    id,
    attributes: { name },
    associatedChargerIds,
  } = device;

  const navigation = useNavigation();
  const { data: deviceStatus = DeviceStatus.NOT_REGISTERED } = useDeviceStatus(id, {
    refetchInterval: DEVICE_STATUS_REFRESH_MILLISECONDS,
  });

  const isOnline = statusToDeviceOnline[deviceStatus];

  const handlePress = () => {
    if (onPress) onPress();
    if (Platform.OS === 'web') {
      navigation.push(RouteId.VehicleDashboard, { deviceId: id });
    } else {
      const removeListener = navigation.addListener('state', () => {
        InteractionManager.runAfterInteractions(() => {
          removeListener();
          navigation.push(RouteId.VehicleDashboard, { deviceId: id });
        });
      });
    }
  };

  if (associatedChargerIds.length > 0) {
    return (
      <PairedVehicle
        id={id}
        name={name}
        onVehiclePress={handlePress}
        onChargerPress={onPress}
        selectedDeviceId={selectedDeviceId}
        chargerIds={associatedChargerIds}
      />
    );
  }

  return (
    <DeviceItem name={name} onPress={handlePress} selected={id === selectedDeviceId} icon={CarIcon} online={isOnline} />
  );
};
