export const colors = {
  fogGrey: '#c9c9c9',
  lightGrey: '#f7f7f7',
  black: '#000',
  dodgerBlue: '#3DA2FF',
  lunarGrey: '#444644',
  fineOrange: '#d6921c',
  punchRed: '#b83e4d',
  raspberryPink: '#EF476F',
  roseRed: '#EF4747',

  // New colors
  white: '#fff',
  deepNavy: '#192240',
  aqua: '#1AE9E3',
  pidgeonGray: '#C3D0E5',
  sunshineYellow: '#FFBE62',
  beachYellow: '#FFCB62',
  morningYellow: '#FFB062',
  hintGrey: '#97A2B2',
  stoneGrey: '#667085',
  smokeGrey: '#EDF4FF',
  disabledGrey: '#DAE3F2',
  jungleGreen: '#15A87B',

  // Wallbox-specific colors
  persianGreen: '#009B86',
  shark: '#202124',
};
