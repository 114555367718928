import { Button, EmailIcon, Link, LockedIcon } from '@hiven-energy/hiven-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { StatusBar } from 'expo-status-bar';
import React, { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';
import { z } from 'zod';

import { useA11y } from 'src/a11y';
import { TextField } from 'src/components/form-fields/TextField';
import { Form } from 'src/components/Form/Form';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useSignIn } from 'src/queries/session';
import { useAnalytics } from 'src/services/analytics';
import { CognitoAction } from 'src/services/session';
import { useSession } from 'src/store/session';
import * as validators from 'src/utils/validators';

import { getCognitoErrorMessageId } from '../utils';

import * as styled from './styles';
import { SignInFormData } from './types';

type Props = ScreenProps<RouteId.SignIn>;

const SignIn: FC<Props> = ({ route, navigation }) => {
  const intl = useIntl();
  const a11y = useA11y();
  const toast = useToast();
  const session = useSession();

  const { trackButtonClick, trackLoggedIn } = useAnalytics();

  const schema = useMemo(
    () =>
      z.object({
        email: validators.email(intl),
        password: validators.password(intl, true),
      }),
    [intl],
  );

  const form = useForm<SignInFormData>({
    mode: 'onBlur',
    defaultValues: {
      email: route.params?.email || '',
      password: '',
    },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    form.setValue('email', route.params?.email || '');
  }, [route.params?.email]);

  const signInMutation = useSignIn({
    onSuccess: async () => {
      const user = await session.initialize();
      if (!user) return;
      trackLoggedIn('SignIn.button', user.email);
      console.log('User was successfully logged in', user);
    },
    onError: error => {
      const errorMessageId = getCognitoErrorMessageId(CognitoAction.SIGN_IN, error);
      if (errorMessageId.includes('UserNotConfirmedException')) {
        navigation.navigate(RouteId.ConfirmSignUp, { email: form.getValues().email, sendCode: true });
        return;
      }
      toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
    },
  });

  const handleSignIn = (data: SignInFormData) => {
    signInMutation.mutate(data);
  };

  const handleForgetPasswordPress = () => {
    trackButtonClick('SignIn.forgottenPwdLink');
    navigation.navigate(RouteId.ResetPassword, { email: form.getValues().email });
  };

  const handleSignUpPress = () => {
    trackButtonClick('SignIn.signUpLink');
    navigation.navigate(RouteId.SignUp, { email: form.getValues().email });
  };

  return (
    <styled.Container>
      <Form>
        <styled.Title variant="h3" accessibilityLabel={a11y.formatLabel('SignIn.title')}>
          <FormattedMessage id="SignIn.title" />
        </styled.Title>
        <TextField
          placeholder={intl.formatMessage({ id: 'common.email' })}
          control={form.control}
          name="email"
          leftIcon={EmailIcon}
          onFocus={() => form.clearErrors('email')}
        />
        <TextField
          placeholder={intl.formatMessage({ id: 'common.password' })}
          control={form.control}
          name="password"
          leftIcon={LockedIcon}
          onFocus={() => form.clearErrors('password')}
          password
        />
        <styled.LinkContainer>
          <Link onPress={handleForgetPasswordPress} accessibilityLabel={a11y.formatLabel('SignIn.forgottenPwdLink')}>
            <FormattedMessage id="SignIn.resetPasswordLink" />
          </Link>
        </styled.LinkContainer>
        <styled.Footer>
          <Button
            title={intl.formatMessage({ id: 'SignIn.button' })}
            loading={signInMutation.isLoading}
            disabled={signInMutation.isLoading || !form.formState.isValid}
            testID={a11y.formatLabel('SignIn.button')}
            accessible
            onPress={form.handleSubmit(handleSignIn)}
          />
          <styled.SignUpQuestion>
            <styled.SignUpText accessibilityLabel={a11y.formatLabel('SignIn.signUpQn')}>
              <FormattedMessage id="SignIn.signUpQuestion" />
            </styled.SignUpText>
            <Link onPress={handleSignUpPress} accessibilityLabel={a11y.formatLabel('SignIn.signUpLink')}>
              <FormattedMessage id="SignIn.signUpLink" />
            </Link>
          </styled.SignUpQuestion>
        </styled.Footer>
      </Form>
      <StatusBar style="light" />
    </styled.Container>
  );
};

export default SignIn;
