import {
  Charger,
  ChargerIntegration,
  Device,
  DeviceType,
  UserLocation,
  Vehicle,
  VehicleIntegration,
} from '@hiven-energy/hiven-client';
import orderBy from 'lodash/orderBy';

import { diffSeconds, getDate } from './date';

export const DEVICE_CONNECTING_THRESHOLD_SECONDS = 5 * 60;

export type DeviceIntegration = VehicleIntegration | ChargerIntegration;

export const isVehicle = (device: Device): device is Vehicle => device.type === DeviceType.VEHICLE;

export const isCharger = (device: Device): device is Charger => device.type === DeviceType.CHARGER;

export const findNewDevice = (previousDevices: Device[], devices: Device[]) =>
  devices.find(device => !previousDevices.find(previousDevice => previousDevice.id === device.id));

export const isDeviceRecentlyAdded = (device: Device) =>
  diffSeconds(getDate(), getDate(device.createdAt)) <= DEVICE_CONNECTING_THRESHOLD_SECONDS;

export const deviceTypeByOrder: DeviceType[] = [DeviceType.VEHICLE, DeviceType.CHARGER];

export const sortDevices = (devices: Device[]): Device[] =>
  orderBy(devices, [device => deviceTypeByOrder.indexOf(device.type), 'attributes.name'], ['asc', 'asc']);

export const groupDevicesByLocation = (locations: UserLocation[], devices: Device[]) =>
  locations.reduce<Record<string, Device[]>>((acc, location) => {
    acc[location.id] =
      devices.filter(
        device =>
          (isCharger(device) && device.settings.chargingPreferences?.locationId === location.id) ||
          (isVehicle(device) && device.settings.chargingPreferences.chargingLocationIds?.includes(location.id)),
      ) ?? [];
    return acc;
  }, {});
