import { getSystemLanguage } from '../i18n';
import { SessionState } from '../store/session';

export const sessionState: SessionState = {
  language: getSystemLanguage(),
  loggedIn: true,
  userId: '42a84fde-faa3-4c5f-89f8-6c33a63d0293',
  email: 'test@test.test',
  resetState: () => undefined,
  initialize: async () => null,
  getAuthorizationToken: async () => '',
  setLanguage: () => undefined,
};
