import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { Animated, Platform, TouchableOpacity } from 'react-native';
import styled, { css } from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  align-items: ${Platform.OS === 'web' ? 'normal' : 'center'};
`;

export const Header = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  gap: ${spacings.xxxs}px;
  margin: 0 ${spacings.xs}px;
`;

export const HeaderSpace = styled(_Container)`
  ${Platform.OS === 'web' &&
  css`
    flex-grow: 1;
  `}
`;

export const Subheader = styled(_Container)`
  align-items: center;
`;

export const ToggleContent = styled(Animated.View)`
  align-self: stretch;
`;

export const SubTitle = styled(Typography).attrs({ variant: 'hint' })`
  color: ${colors.pidgeonGray};
`;

export const Title = styled(Typography).attrs({ variant: 'h1' })`
  text-align: center;
  color: ${colors.white};
`;
