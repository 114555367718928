import { Charger, ChargerPreferences, UserLocation } from '@hiven-energy/hiven-client';
import { useNavigation } from '@react-navigation/native';
import React, { FC, useMemo } from 'react';

import Divider from 'src/components/Divider/Divider';
import BatteryCapacity from 'src/containers/device-overview/BatteryCapacity/BatteryCapacity';
import ChargeLevels from 'src/containers/device-overview/ChargeLevels/ChargeLevels';
import ChargingLocations from 'src/containers/device-overview/ChargingLocations/ChargingLocations';
import ReadyTime from 'src/containers/device-overview/ReadyTime/ReadyTime';
import { RouteId } from 'src/nav/types';
import { useVehiclePreferences, useVehicleTelemetry } from 'src/queries/sdk';
import { PreferenceType } from 'src/screens/charger/types';

import ChargingBehavior from './ChargingBehavior/ChargingBehavior';
import * as styled from './styles';

interface Props {
  charger: Charger;
  preferences?: ChargerPreferences;
  userLocations?: UserLocation[];
  showDividers?: boolean;
}

const ChargerChargingSetup: FC<Props> = ({ charger, preferences, userLocations = [], showDividers = true }) => {
  const navigation = useNavigation();

  const standalone = !preferences?.associatedDeviceId;

  const { data: vehicleTelemetry } = useVehicleTelemetry(preferences?.associatedDeviceId as string, {
    enabled: !standalone,
  });
  const { data: vehiclePreferences } = useVehiclePreferences(preferences?.associatedDeviceId as string, {
    enabled: !standalone,
  });

  const locations = useMemo(() => {
    if (!userLocations || !preferences) return [];
    const chargingLocation = userLocations.find(location => location.id === preferences.locationId);
    return chargingLocation ? [chargingLocation] : [];
  }, [userLocations, preferences]);

  const navigateToPreference = (preferenceType: PreferenceType) => {
    navigation.navigate(RouteId.ChargerPreferences, {
      deviceId: charger.id,
      currentPreferenceType: preferenceType,
      updateOnPreferenceConfirm: true,
      associatedDeviceId: preferences?.associatedDeviceId,
    });
  };

  const handleChargingLocationsEditPress = () => {
    navigateToPreference(PreferenceType.YOUR_CHARGING_LOCATIONS);
  };

  const handleUserLocationEditPress = (locationId: string) => {
    navigation.navigate(RouteId.ChargingLocation, { locationId });
  };

  const handleTariffInfoEditPress = (locationId: string) => {
    navigation.navigate(RouteId.SetTariffAndMeter, { locationId });
  };

  const handleBatteryCapacityEditPress = () => {
    navigateToPreference(PreferenceType.BATTERY_CAPACITY);
  };

  const handleReadyTimeEditPress = () => {
    navigateToPreference(PreferenceType.READY_TIME);
  };

  const handleChargeLevelsEditPress = () => {
    navigateToPreference(PreferenceType.CHARGE_LEVELS);
  };

  const handleChargingBehaviorEditPress = () => {
    navigateToPreference(PreferenceType.CHARGING_BEHAVIOR);
  };

  return (
    <styled.Container>
      <ChargingLocations
        locations={locations}
        showTariffInfo
        onEditPress={handleChargingLocationsEditPress}
        onLocationEditPress={handleUserLocationEditPress}
        onTariffInfoEditPress={handleTariffInfoEditPress}
      />
      {showDividers && <Divider />}
      {!standalone && (
        <>
          <BatteryCapacity
            capacity={vehicleTelemetry?.batteryCapacity ?? vehiclePreferences?.batteryCapacity ?? undefined}
            onEditPress={vehicleTelemetry?.batteryCapacity ? undefined : handleBatteryCapacityEditPress}
          />
          {showDividers && <Divider />}
        </>
      )}
      <ReadyTime
        readyTime={preferences?.readyTime}
        daysOfWeekSpecificReadyTime={preferences?.weekDaySpecificReadyTimes}
        onEditPress={handleReadyTimeEditPress}
      />
      {showDividers && <Divider />}
      <ChargeLevels
        minimum={standalone ? preferences?.minimumEnergyTransfer?.value : preferences?.minimalSoC?.value}
        maximum={standalone ? preferences?.targetEnergyTransfer?.value : preferences?.targetSoC?.value}
        unit={standalone ? 'kWh' : '%'}
        onEditPress={handleChargeLevelsEditPress}
      />
      {showDividers && <Divider />}
      <ChargingBehavior
        chargingBehavior={preferences?.chargingBehavior}
        onEditPress={handleChargingBehaviorEditPress}
      />
    </styled.Container>
  );
};

export default React.memo(ChargerChargingSetup);
