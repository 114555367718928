import { ChargingSchedule, SpotPriceArea } from '@hiven-energy/hiven-client';

export const chargingSchedule: ChargingSchedule = {
  id: 'ID',
  createdAt: '2024-01-01T10:10:00.000Z',
  readyTime: '2024-01-01T10:10:00.000Z',
  spotPriceAreaId: SpotPriceArea.PL,
  timeZone: 'TZ',
  hourlyCharges: {
    1: 10,
  },
};
