import { CheckIcon, ExternalIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import background from 'src/assets/smartCarConnect/success/background.png';
import Hexagon from 'src/components/Hexagon/Hexagon';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { MixpanelEventProps } from 'src/services/analytics/types';
import { colors } from 'src/theme';
import { openBrowser } from 'src/utils/browser';

import * as styled from './styles';

interface Props {
  analytics: Pick<MixpanelEventProps<MixpanelEvents.VEHICLE_ADDED>, 'DeviceType' | 'Code'>;
  virtualKeyUrl?: string;
  onContinue: VoidFunction;
}

const Success: FC<Props> = ({ virtualKeyUrl, analytics, onContinue }) => {
  const intl = useIntl();

  const { trackVehicleAdded, trackButtonClick } = useAnalytics();

  const handleAddVirtualKeyPress = () => {
    trackButtonClick('SmartCarConnect.success.virtualKey.button');
    openBrowser(virtualKeyUrl!, true);
  };

  const handleContinuePress = () => {
    trackVehicleAdded('common.continue', analytics.DeviceType, analytics.Code);
    onContinue();
  };

  return (
    <styled.Container>
      <Hexagon>
        <CheckIcon size={40} />
      </Hexagon>
      <styled.Title variant="h1">
        <FormattedMessage id="SmartCarConnect.success" />
      </styled.Title>
      {virtualKeyUrl && (
        <styled.VirtualKeyHint variant="h4">
          <FormattedMessage id="SmartCarConnect.success.virtualKey.disclaimer" />
        </styled.VirtualKeyHint>
      )}
      <styled.ButtonsContainer>
        {virtualKeyUrl && (
          <styled.VirtualKeyAdditionButton
            title={intl.formatMessage({ id: 'SmartCarConnect.success.virtualKey.button' })}
            onPress={handleAddVirtualKeyPress}
            rightIcon={() => <ExternalIcon color={colors.lunarGrey} />}
          />
        )}
        <styled.ContinueButton
          type={virtualKeyUrl ? 'primary' : 'tertiary'}
          colors={
            virtualKeyUrl
              ? {
                  enabled: { borderColor: colors.pidgeonGray },
                  pressed: { borderColor: colors.pidgeonGray },
                }
              : undefined
          }
          title={intl.formatMessage({ id: 'common.continue' })}
          onPress={handleContinuePress}
        />
      </styled.ButtonsContainer>
      <styled.ImageBackground source={background} />
    </styled.Container>
  );
};

export default Success;
