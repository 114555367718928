import { Button, CheckIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import Hexagon from 'src/components/Hexagon/Hexagon';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';
import { colors } from 'src/theme';

import * as styled from './styles';

type Props = ScreenProps<RouteId.ConfirmSuccess>;

const ConfirmSuccess: FC<Props> = ({ route, navigation }) => {
  const intl = useIntl();
  const a11y = useA11y();

  const { trackButtonClick } = useAnalytics();

  const handleGetStartedPress = () => {
    trackButtonClick('ConfirmSuccess.button');
    navigation.navigate(RouteId.SignIn, { email: route.params?.email || '' });
  };

  return (
    <styled.Container>
      <styled.Header>
        <styled.Icon>
          <Hexagon>
            <CheckIcon color={colors.white} size={40} />
          </Hexagon>
        </styled.Icon>
        <styled.Title variant="h3" accessibilityLabel={a11y.formatLabel('ConfirmSuccess.title')}>
          <FormattedMessage id="ConfirmSuccess.title" />
        </styled.Title>
        <styled.Content accessibilityLabel={a11y.formatLabel('ConfirmSuccess.content')}>
          <FormattedMessage id="ConfirmSuccess.content" />
        </styled.Content>
      </styled.Header>
      <styled.Footer>
        <Button title={intl.formatMessage({ id: 'ConfirmSuccess.button' })} onPress={handleGetStartedPress} />
      </styled.Footer>
    </styled.Container>
  );
};

export default ConfirmSuccess;
