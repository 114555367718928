import { ChargerChargingBehavior, TariffInfo } from '@hiven-energy/hiven-client';

import { ChargeLevels } from 'src/containers/charge-levels/types';
import { ReadyTimeByType } from 'src/containers/ready-time/types';
import { Address, Region } from 'src/services/maps';

export enum SetupType {
  STANDALONE = 'Standalone',
  ASSOCIATED = 'Associated',
}

export enum PreferenceType {
  ADD_CHARGING_LOCATION = 'AddChargingLocation',
  YOUR_CHARGING_LOCATIONS = 'YourChargingLocations',
  DEVICE_PAIRING = 'DevicePairing',
  BATTERY_CAPACITY = 'BatteryCapacity',
  READY_TIME = 'ReadyTime',
  CHARGE_LEVELS = 'ChargeLevels',
  CHARGING_BEHAVIOR = 'ChargingBehavior',
  TARIFF_INFORMATION = 'TariffInformation',
}

export interface Preferences {
  setupType: SetupType;
  chargingLocation: ChargingLocation;
  associatedVehicleId: string | undefined;
  batteryCapacity: number | undefined;
  readyTime: ReadyTimeByType;
  chargeLevels: ChargeLevels;
  chargingBehavior: ChargerChargingBehavior;
}

export interface ChargingLocation {
  name: string;
  region: Region;
  address: Required<Address> | undefined;
  tariffInfo: TariffInfo | undefined;
  chargingLocationId?: string;
}
