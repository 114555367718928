import {
  Charger,
  ChargerChargingBehavior,
  ChargerPreferences,
  ChargerStatus,
  ChargingSchedule,
  DeviceStatus,
} from '@hiven-energy/hiven-client';
import { IntlShape } from 'react-intl';

import { Property } from 'src/containers/device-overview/Property/Property';
import { statusToMessage } from 'src/containers/device-status/constants';
import { formatRelativeTime } from 'src/utils/time';
import { convertEnergyUnits, EnergyUnit, formatEnergy } from 'src/utils/units';

import { arePreferencesSet } from '../../utils';

export const prepareDetails = (charger: Charger, status: ChargerStatus | undefined, intl: IntlShape): Property[] => [
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.deviceId' }),
    value: charger.id,
    copyable: true,
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.firstConnection' }),
    value: formatRelativeTime(charger.createdAt, intl),
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.deviceDetails.lastConnection' }),
    value: status?.heartbeatTimestamp && formatRelativeTime(status.heartbeatTimestamp, intl),
  },
];

export const prepareDiagnostics = (
  status: DeviceStatus | undefined,
  chargerStatus: ChargerStatus | undefined,
  preferences: ChargerPreferences | undefined,
  schedule: ChargingSchedule | undefined,
  intl: IntlShape,
): Property[] => [
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.diagnostics.smartCharge' }),
    value: preferences?.chargingBehavior
      ? preferences.chargingBehavior === ChargerChargingBehavior.SMART_CHARGE_OFF
        ? intl.formatMessage({ id: 'common.disabled' })
        : intl.formatMessage({ id: 'common.enabled' })
      : undefined,
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.diagnostics.chargingStatus' }),
    value: status && intl.formatMessage(statusToMessage[status]),
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.diagnostics.scheduleAvailable' }),
    value: !!schedule,
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.diagnostics.preferencesSet' }),
    value: !!preferences && arePreferencesSet(preferences),
  },
  {
    name: intl.formatMessage({ id: 'charger.deviceOverview.diagnostics.currentEnergyTransfer' }),
    value:
      chargerStatus?.measurement &&
      formatEnergy(convertEnergyUnits(chargerStatus.measurement.value, EnergyUnit.WH, EnergyUnit.KWH), EnergyUnit.KWH),
  },
  {
    name: intl.formatMessage({ id: 'common.deviceOverview.diagnostics.effectiveReadyTime' }),
    value: schedule && formatRelativeTime(schedule.readyTime, intl),
  },
];

export const prepareConnectionSettings = (
  charger: Charger,
  steveUrl: string | undefined,
  intl: IntlShape,
): Property[] => [
  {
    name: intl.formatMessage({ id: 'charger.deviceOverview.ocppSettings.ocppUrl' }),
    value: steveUrl ? `${steveUrl}/${charger.attributes.externalId}` : undefined,
    copyable: true,
  },
  {
    name: intl.formatMessage({ id: 'charger.deviceOverview.ocppSettings.chargePointIdentity' }),
    value: charger?.attributes.externalId,
    copyable: true,
  },
  {
    name: intl.formatMessage({ id: 'charger.deviceOverview.ocppSettings.password' }),
    value: intl.formatMessage({ id: 'charger.deviceOverview.ocppSettings.password.value' }),
  },
];
