import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors, MAX_CONTENT_WIDTH } from 'src/theme';

export const Container = styled(_Container)`
  background-color: ${colors.deepNavy};
  flex: 1;
  padding: ${spacings.xs}px;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Typography)`
  color: ${colors.white};
  padding: 0 ${spacings.xs}px;
  margin-top: ${spacings.m}px;
  margin-bottom: ${spacings.l}px;
  text-align: center;
  max-width: ${MAX_CONTENT_WIDTH}px;
`;
