import { Button, Radio } from '@hiven-energy/hiven-ui';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import { Language } from 'src/i18n';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useUpdateLanguage } from 'src/queries/session';
import { useAnalytics } from 'src/services/analytics';
import { useSession } from 'src/store/session';

import * as styled from './styles';

type Props = ScreenProps<RouteId.ChangeLanguage>;

const ChangeLanguage: FC<Props> = ({ navigation }) => {
  const intl = useIntl();
  const toast = useToast();
  const session = useSession();
  const { trackLanguageChanged } = useAnalytics();
  const currentLangRef = useRef<Language>(session.language);
  const [language, setLanguage] = useState(session.language);
  const updateLanguageMutation = useUpdateLanguage();

  useEffect(() => {
    if (currentLangRef.current !== session.language) {
      toast.show(intl.formatMessage({ id: 'ChangeLanguage.success' }), { type: 'success' });
      navigation.navigate(RouteId.Account);
    }
    currentLangRef.current = session.language;
  }, [session.language]);

  const handleConfirm = () => {
    updateLanguageMutation.mutate(language, {
      onSuccess: () => {
        session.setLanguage(language);
        trackLanguageChanged(language);
      },
      onError: () => {
        toast.show(intl.formatMessage({ id: 'ChangeLanguage.error' }), { type: 'danger' });
      },
    });
  };

  return (
    <styled.Container>
      <styled.Title variant="h3">
        <FormattedMessage id="ChangeLanguage.title" />
      </styled.Title>
      <styled.ToggleWrapper>
        <Radio
          checked={language === Language.EN}
          label={intl.formatMessage({ id: 'common.language.english' })}
          onChange={() => setLanguage(Language.EN)}
        />
        <Radio
          checked={language === Language.FI}
          label={intl.formatMessage({ id: 'common.language.finnish' })}
          onChange={() => setLanguage(Language.FI)}
        />
      </styled.ToggleWrapper>
      <styled.Footer>
        <Button
          title={intl.formatMessage({ id: 'common.save' })}
          loading={updateLanguageMutation.isLoading}
          disabled={language === session.language}
          onPress={handleConfirm}
        />
      </styled.Footer>
    </styled.Container>
  );
};

export default ChangeLanguage;
