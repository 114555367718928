import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Loader from 'src/components/Loader/Loader';
import { useChargerTelemetry } from 'src/queries/sdk';
import { colors } from 'src/theme';
import { formatRelativeTime } from 'src/utils/time';

import * as styled from './styles';

interface Props {
  chargerId: string;
}

export const ChargerHeartBeat: FC<Props> = ({ chargerId }) => {
  const intl = useIntl();
  const chargerTelemetryQuery = useChargerTelemetry(chargerId);

  return (
    <styled.Text>
      {chargerTelemetryQuery.isLoading ? (
        <Loader color={colors.deepNavy} />
      ) : (
        <FormattedMessage
          id="ChargingLocation.charger.statusHeartbeat"
          values={{
            value: chargerTelemetryQuery.data
              ? formatRelativeTime(chargerTelemetryQuery.data.heartbeatTimestamp, intl)
              : '-',
          }}
        />
      )}
    </styled.Text>
  );
};
