import { Container as _Container, Button as HivenButton, spacings, Typography } from '@hiven-energy/hiven-ui';
import { ImageBackground as NativeImageBackground, Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  padding: ${spacings.xs}px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Title = styled(Typography)`
  color: ${colors.white};
  padding: 0 ${spacings.xs}px;
  margin-top: ${spacings.m}px;
  text-align: center;
`;

export const ContinueButton = styled(HivenButton).attrs({})``;

export const ButtonsContainer = styled(_Container)`
  position: absolute;
  bottom: 0;
  width: 100%;
  gap: ${spacings.xs}px;
  z-index: 1;

  ${() =>
    Platform.OS !== 'ios'
      ? css`
          padding: ${spacings.s}px ${spacings.xs}px;
        `
      : css`
          padding-bottom: ${spacings.xs}px;
        `}
`;

export const VirtualKeyAdditionButton = styled(HivenButton).attrs({
  type: 'primary',
  colors: {
    enabled: { titleColor: colors.deepNavy, backgroundColor: colors.white },
    pressed: { titleColor: colors.deepNavy, backgroundColor: colors.aqua },
  },
  styles: {
    rightIconWrapper: { position: 'absolute', right: spacings.xxxs },
  },
})``;

export const VirtualKeyHint = styled(Typography)`
  color: ${colors.white};
  margin-top: ${spacings.xxs}px;
  text-align: center;
`;

export const ImageBackground = styled(NativeImageBackground)`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 325px;
`;
