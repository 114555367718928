import { APP_ENV } from '@env';
import { VehicleManufacturer } from '@hiven-energy/hiven-client';
import { colors, ExternalIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DeviceLogo from 'src/components/DeviceLogo/DeviceLogo';
import { RouteId, ScreenProps } from 'src/nav/types';
import { VehicleManufacturerWithoutStartStop } from 'src/screens/brands/VehicleBrands/types';
import { openBrowser } from 'src/utils/browser';

import { vehicleManufacturerToNameMap } from '../brands/VehicleBrands/constants';

import * as styled from './styles';

export interface RoutedProps {
  manufacturer: VehicleManufacturer | VehicleManufacturerWithoutStartStop;
}

type Props = ScreenProps<RouteId.SmartCarDocs>;

const FAQ_URL = `https://${APP_ENV !== 'production' ? 'test.' : ''}hiven.energy/faq?article=ev-support`;

const SmartCarDocs: FC<Props> = ({
  route: {
    params: { manufacturer },
  },
  navigation,
}) => {
  const intl = useIntl();

  return (
    <styled.Container>
      <styled.LogoContent>
        <DeviceLogo manufacturer={manufacturer} width={132} height={132} />
      </styled.LogoContent>
      <styled.Description variant="h3">
        <FormattedMessage
          id="SmartCarDocs.description"
          values={{
            manufacturer: vehicleManufacturerToNameMap[manufacturer],
          }}
        />
      </styled.Description>
      <styled.Description variant="h3">
        <FormattedMessage id="SmartCarDocs.hint" />
      </styled.Description>
      <styled.BottomContent>
        <styled.Button
          type="secondary"
          title={intl.formatMessage({ id: 'SmartCarDocs.link' })}
          onPress={() => openBrowser(FAQ_URL, true)}
          styles={{ title: { flexGrow: 1, textAlign: 'center', color: colors.stoneGrey } }}
          rightIcon={() => <ExternalIcon color={colors.stoneGrey} />}
        />
        <styled.Button
          type="primary"
          title={intl.formatMessage({ id: 'common.continue' })}
          onPress={() => navigation.navigate(RouteId.SmartCarConnect, { manufacturer })}
        />
      </styled.BottomContent>
    </styled.Container>
  );
};

export default SmartCarDocs;
