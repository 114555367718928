import { ChargerManufacturer } from '@hiven-energy/hiven-client';
import { Button, EmailIcon, LockedIcon } from '@hiven-energy/hiven-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';
import { z } from 'zod';

import { useA11y } from 'src/a11y';
import DeviceLogo from 'src/components/DeviceLogo/DeviceLogo';
import { TextField } from 'src/components/form-fields/TextField';
import { useConnectSuccessTarget } from 'src/hooks/useConnectSuccessTarget';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useRegisterWallboxAccount } from 'src/queries/sdk';
import { SignInFormData } from 'src/screens/authentication/SignIn/types';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import * as validators from 'src/utils/validators';

import * as styled from './styles';

type Props = ScreenProps<RouteId.WallboxLogin>;

const WallboxLogin: FC<Props> = ({ navigation }) => {
  const a11y = useA11y();
  const intl = useIntl();
  const toast = useToast();
  const successTarget = useConnectSuccessTarget();

  useAnalyticsTimeEvent(MixpanelEvents.CHARGER_REGISTERED);
  const { trackRegisterWallboxAccount } = useAnalytics();

  const registerWallboxAccountMutation = useRegisterWallboxAccount({
    onSuccess: () => {
      const message = intl.formatMessage({ id: 'WallboxLogin.success' });
      toast.show(message, { type: 'success' });
      navigation.navigate(RouteId.WallboxConnect);
    },
    onError: () => {
      const message = intl.formatMessage({ id: 'WallboxLogin.error' });
      toast.show(message, { type: 'danger' });
    },
  });

  const schema = useMemo(
    () =>
      z.object({
        email: validators.email(intl),
        password: z
          .string({ required_error: intl.formatMessage({ id: 'validation.required' }) })
          .min(1, 'validation.required'),
      }),
    [intl],
  );

  const form = useForm<SignInFormData>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(schema),
  });

  const handleSignInPress = (data: SignInFormData) => {
    successTarget.saveTarget();
    trackRegisterWallboxAccount('WallboxLogin.submit', data.email);
    registerWallboxAccountMutation.mutate({
      username: data.email,
      password: data.password,
    });
  };

  return (
    <styled.Container>
      <styled.Icon>
        <DeviceLogo manufacturer={ChargerManufacturer.WALLBOX} long />
      </styled.Icon>
      <styled.Title variant="h3">
        <FormattedMessage id="WallboxLogin.title" />
      </styled.Title>
      <TextField
        placeholder={intl.formatMessage({ id: 'common.email' })}
        control={form.control}
        name="email"
        leftIcon={EmailIcon}
        onFocus={() => form.clearErrors('email')}
      />
      <TextField
        placeholder={intl.formatMessage({ id: 'common.password' })}
        control={form.control}
        name="password"
        leftIcon={LockedIcon}
        onFocus={() => form.clearErrors('password')}
        password
      />
      <styled.Footer>
        <Button
          title={intl.formatMessage({ id: 'WallboxLogin.submit' })}
          loading={registerWallboxAccountMutation.isLoading}
          disabled={registerWallboxAccountMutation.isLoading || !form.formState.isValid}
          testID={a11y.formatLabel('WallboxLogin.submit')}
          onPress={form.handleSubmit(handleSignInPress)}
        />
      </styled.Footer>
    </styled.Container>
  );
};

export default WallboxLogin;
