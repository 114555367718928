import {
  Charger,
  ChargerChargingBehavior,
  ChargerPreferences,
  ChargerStatus,
  ChargingSchedule,
  DeviceStatus,
  UserLocation,
} from '@hiven-energy/hiven-client';
import React, { FC, useCallback, useState } from 'react';
import { Animated } from 'react-native';

import BaseView from 'src/components/BaseView/BaseView';
import ChargingSetup from 'src/containers/device-overview/ChargerChargingSetup/ChargerChargingSetup';
import DeviceImage from 'src/containers/device-overview/DeviceImage/DeviceImage';
import FetchError from 'src/containers/device-overview/FetchError/FetchError';
import Header from 'src/containers/device-overview/Header/Header';
import Loader from 'src/containers/device-overview/Loader/Loader';
import Scroll from 'src/containers/device-overview/Scroll/Scroll';
import Tabs, { Tab } from 'src/containers/device-overview/Tabs/Tabs';
import { statusToDeviceOnline } from 'src/containers/device-status/constants';
import { useAnimatedValue } from 'src/hooks/useAnimatedValue';
import { RouteId, ScreenProps } from 'src/nav/types';
import {
  useCharger,
  useChargerPreferences,
  useChargerTelemetry,
  useChargingSchedule,
  useDeviceStatus,
  useUserLocations,
} from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';

import DeviceDetails from './DeviceDetails/DeviceDetails';

type Props = ScreenProps<RouteId.ChargerOverview>;

export interface RoutedProps {
  deviceId: string;
}

interface ContentProps {
  charger: Charger;
  deviceStatus: DeviceStatus | undefined;
  chargerStatus: ChargerStatus | undefined;
  preferences?: ChargerPreferences;
  schedule?: ChargingSchedule;
  userLocations?: UserLocation[];
}

const Overview: FC<Props> = ({ route }) => {
  const { deviceId } = route.params;

  const { trackRefresh } = useAnalytics();

  const chargerQuery = useCharger(deviceId);
  const { data: deviceStatus = DeviceStatus.NOT_REGISTERED, refetch: refetchDeviceStatus } = useDeviceStatus(deviceId);
  const chargerTelemetryQuery = useChargerTelemetry(deviceId);
  const deviceStatusQuery = useDeviceStatus(deviceId);

  const preferencesQuery = useChargerPreferences(deviceId);
  const userLocationsQuery = useUserLocations();

  const standalone = !preferencesQuery.data?.associatedDeviceId;
  const smartChargeEnabled =
    !!preferencesQuery.data && preferencesQuery.data.chargingBehavior === ChargerChargingBehavior.SMART_CHARGE_ON;

  const scheduleQuery = useChargingSchedule(deviceId, { enabled: smartChargeEnabled });

  const scroll = useAnimatedValue(0);

  const handleScroll = Animated.event(
    [
      {
        nativeEvent: {
          contentOffset: { y: scroll },
        },
      },
    ],
    { useNativeDriver: true },
  );

  const refresh = useCallback(async () => {
    trackRefresh();
    await Promise.all([
      refetchDeviceStatus(),
      chargerQuery.refetch(),
      chargerTelemetryQuery.refetch(),
      preferencesQuery.refetch(),
      standalone && userLocationsQuery.refetch(),
      deviceStatusQuery.refetch(),
      smartChargeEnabled && scheduleQuery.refetch(),
    ]);
  }, [
    refetchDeviceStatus,
    standalone,
    smartChargeEnabled,
    chargerQuery.refetch,
    chargerTelemetryQuery.refetch,
    preferencesQuery.refetch,
    userLocationsQuery.refetch,
    scheduleQuery.refetch,
    deviceStatusQuery.refetch,
    trackRefresh,
  ]);

  if (chargerQuery.isLoading || deviceStatusQuery.isLoading) {
    return <Loader />;
  }

  if (chargerQuery.isError || !chargerQuery.data || deviceStatusQuery.isError) {
    return <FetchError />;
  }

  const Content = standalone ? StandaloneChargerContent : PairedChargerContent;

  return (
    <BaseView>
      <DeviceImage deviceType={chargerQuery.data.type} scroll={scroll} />
      <Scroll refresh={refresh} onScroll={handleScroll}>
        <Header
          deviceType={chargerQuery.data.type}
          name={chargerQuery.data.attributes.name}
          heartbeat={chargerTelemetryQuery.data?.heartbeatTimestamp}
          isOnline={statusToDeviceOnline[deviceStatus]}
        />
        <Content
          charger={chargerQuery.data}
          deviceStatus={deviceStatusQuery.data}
          chargerStatus={chargerTelemetryQuery.data}
          preferences={preferencesQuery.data}
          schedule={scheduleQuery.isSuccess ? scheduleQuery.data : undefined}
          userLocations={userLocationsQuery.data}
        />
      </Scroll>
    </BaseView>
  );
};

const StandaloneChargerContent: FC<ContentProps> = ({
  charger,
  chargerStatus,
  deviceStatus,
  preferences,
  schedule,
  userLocations,
}) => {
  const [activeTab, setActiveTab] = useState(Tab.CHARGING_SETUP);

  return (
    <>
      <Tabs active={activeTab} onChange={setActiveTab} />
      {activeTab === Tab.CHARGING_SETUP && (
        <ChargingSetup charger={charger} preferences={preferences} userLocations={userLocations} />
      )}
      {activeTab === Tab.DEVICE_DETAILS && (
        <DeviceDetails
          charger={charger}
          chargerStatus={chargerStatus}
          deviceStatus={deviceStatus}
          preferences={preferences}
          schedule={schedule}
        />
      )}
    </>
  );
};

const PairedChargerContent: FC<ContentProps> = ({ charger, chargerStatus, deviceStatus, preferences, schedule }) => (
  <DeviceDetails
    charger={charger}
    deviceStatus={deviceStatus}
    chargerStatus={chargerStatus}
    preferences={preferences}
    schedule={schedule}
  />
);

export default Overview;
